<template>
    <div class="margin-top-large padleft0 col-xs-12" :class="getOAuthClass('oauth-flow-pr0')">
              <h3 style=" color: #000000;">Verify your delivery method</h3>
              <p class="martop12 marbottom32" v-html="otpDeliveryMsg"></p>
              <v-form class="padleft0">
                <div class="form-group padleft0 col-xs-4" :class="getOAuthClass('oauth-flow-pr0 oauth-flow-w-100')" style="margin-bottom: 0px">
                  <label class="vbg-ff-input-label padleft0 ">Enter one-time passcode</label>
                  <input id="otpCode" name="otpCode" v-model="otpCode" type="text" class="form-control vz-input-field vbg-input-field" ref="otpRef" autocomplete="one-time-code" pattern="[0-9]*" :disabled="!otpSent" :tabindex="devices.length + 6" maxlength="6" @input="otpvalidation">
                </div>
                <div class="padleft0 col-xs-12">
                  <p class="pmicro" v-if="dynamicErrorClasses.otpCode.fieldErrorMsg != ''">{{dynamicErrorClasses.otpCode.fieldErrorMsg}}
                  </p>
                </div>
                <div class="padleft0 col-xs-12"></div>
                <div v-if="this.whichRealm == 'LOGIN'" class="padleft0 col-xs-12 margin-bottom-medium margin-top-64 padding-top-small" :class="getOAuthClass('oauth-flow-pr0')">
                  <button 
                    type="button" 
                    class="btn primary_black verifyContainer remember-device" :disabled="continueDisabled" 
                    :class="continueDisabled? 'disabled': ''"
                    v-if="flowType == 'auth' && !otpAlwaysOn" 
                    @click="validateOtpCode(true)"
                    :tabindex="devices.length + 7" id="otpValidate_verifynRem_button"
                  >
                    {{$t("message.otp_verify")}}{{$t("message.login_register_device")}}
                  </button>

                  <button 
                    type="button" 
                    class="btn primary_black vbg-btn-continue vbg-ff-btn-width120" :disabled="continueDisabled" 
                    v-if="flowType != 'auth' || otpAlwaysOn" 
                    @click="validateOtpCode(false)"
                    :class="continueDisabled? 'disabled': ''"
                    :tabindex="devices.length + 8"
                  >{{$t("message.otp_verify")}}</button>

                  <span v-if="flowType != 'auth'">
                    <button @click="cancelVerification()" class="btn vbg-btn-cancel secondary_white vbg-ff-btn-width120" type="button" :tabindex="devices.length + 9">{{$t("message.cancel")}}</button>
                  </span>
                  <span v-if="flowType == 'auth' && !otpAlwaysOn">
                    <button 
                      data-loc="otpAuthenticationSignIn" 
                      @click="validateOtpCode(false)" 
                      :disabled="continueDisabled" 
                      class="btn secondary_white verifyContainer" 
                      type="button"
                      :tabindex="devices.length + 10" id="otpValidate_verifynDrem_button"
                    >{{$t("message.otp_verify")}}{{$t("message.login_dont_register_device")}}</button>
                  </span>
                  <span v-if="flowType == 'auth' && !otpAlwaysOn" class="vbg-tooltip-container">
                    <!--<button class="bx-tooltip marright0" type="button" style="position: relative;" v-tooltip="content" popover-placement="left" popover-trigger="click mouseenter mouseleave focus blur"></button>-->
                    <button
                      class="vbg-tooltip" 
                      type="button"
                      :data-text="content" id ="validate_tooltip"
                    ></button>
                  </span>
                </div>
                <div v-else id="oauthVerifyButton" class="padleft0 col-xs-12 verifyBtn margin-bottom-medium margin-top-64 padding-top-small" :class="getOAuthClass('oauth-flow-pr0')">
                  <button 
                    type="button" 
                    class="btn primary_black margin-right-medium verifyContainer" 
                    :disabled="continueDisabled" 
                    v-if="flowType == 'auth' && !otpAlwaysOn" 
                    @click="validateOtpCode(false)"
                    :class="continueDisabled? 'disabled': ''"
                    :tabindex="devices.length + 11"
                  >{{$t("message.otp_verify")}}</button>

                  <button 
                    type="button" 
                    class="btn primary_black margin-right-medium" 
                    :disabled="continueDisabled" 
                    v-if="flowType != 'auth' || otpAlwaysOn" 
                    @click="validateOtpCode(false)"
                    :class="continueDisabled? 'disabled': ''"
                    :tabindex="devices.length + 12"
                  >{{$t("message.otp_continue")}}</button>
                  <span v-if="flowType != 'auth'">
                    <button @click="cancelVerification()" class="btn secondary_white vbg-btn-cancel vbg-ff-btn-width120" type="button"
                    :tabindex="devices.length + 13"
                    >{{$t("message.cancel")}}</button>
                  </span>
                </div>
              </v-form>
              <!-- <div class="padleft0 col-xs-12 padding-bottom-small">
                <div v-if="otpCode && (flowType =='auth' || flowType === 'POST_AUTH_FLOW')">
                  <a class="vz-a" @click="$event.preventDefault();validateOtpCode(false);" href="#">{{$t("message.otp_verify")}}{{$t("message.login_dont_register_device")}}</a>
                  <span>
                    <button class="bx-tooltip hidden-xs marright0" type="button" style="position: relative;" v-tooltip="content3"></button>
                  </span>
                </div>
              </div> -->
              <div class="padleft0 col-xs-12 padding-bottom-small">
                <div v-if="otpSent" class="padleft0">
                  <a class="vz-a" @click="$event.preventDefault();resendPasscode();" href="#" :tabindex="devices.length + 14" id="link" name="resend_otp_passcode">{{$t("message.login_otp_page_resend_passcode")}}</a>
                </div>
              </div>
              <div class="padleft0 col-xs-12 padding-bottom-small-2">
                <div v-if="otpSent" class="padleft0">
                  <a class="vz-a" @click="dontHaveOTPAccess()" :tabindex="devices.length + 15" id="otp_not_received" name="otp_not_received">
                    I can't receive a one-time passcode
                  </a>
                </div>
              </div>
              <div>
              <div id="noAccessToOtpModal" class="modal fade">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <h3 class="martop0 marbottom20">Unable to receive a one time passcode?</h3>
                    <a data-dismiss="modal" class="col-xs-1 pull-right close-icon-popup" style="padding: 0px;"></a>
                    <div>
                      <p>Please contact Verizon Customer Service at 1-800-922-0204 for further assistance.</p>
                    </div>
                    <div>
                    <button type="submit" data-dismiss="modal" class="btn primary_black marright20 martop50 oauth-modal-button">
                      <span>Close</span>
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import translations from '@/common/assets/json/messages1.json'
import '@/common/assets/styles/tooltip.css' 
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins'

export default {
  name: 'FFOtpValidateComponent',
  components: {
  },
  mixins: [commonMixin],
  data () {
    return {
        content: translations.en.message['login_verify_and_dont_register_tooltip'],
        showResendOtpLink: false,
        isInFrame: true,
        otpCode: '',
        otpValidationInputClass: "",
        whichRealm: 'LOGIN',
        lastSelectedDevice: {}
        // resendPasscodeToSelectedDevice: false
    }
  },
  mounted () {
    console.log("******iamvar_realm*********", iamvar_realm)
    if(iamvar_realm != 'undefined' || iamvar_realm != 'null') {
      this.whichRealm = iamvar_realm;
    }
    this.changeContextBiocatch('step_up_page_otp_validate')
    if(this.flowType === 'forgot_password'){
      this.updateVerificationMode('otp')
      this.$nextTick(() => {
        this.$refs.otpRef.focus()
      })
    }
    this.lastSelectedDevice = this.selectedOtpDevice
    this.observePasswordField()
  },
  beforeDestroy() {
    if(this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
      async resendPasscode() {
      eventTracking('One Time Passcode Resend Request Initiated','');
        let self = this
        self.otpSent = false
        self.showResendOtpLink = false
        this.resendPasscodeToSelectedDevice = true
        this.resendOtpToDevice(this.selectedOtpDevice)
        
      },
      openUnableReceiveOverlay() {
        this.unableToReceiveOtp()
      },
      validateOtpCode (saveFingerprint) {
        if(!this.otpSent || !this.otpCode) {
          // the continue button is disabled and should not be clickable
        } else {
          eventTracking('One Time Passcode Submitted','');
          this.showResendOtpLink = false

          // trim spaces at the beginning and at the end of otp
          this.otpCode = this.otpCode.trim()

          var otpValidateParams = {
              "otpCode" : this.otpCode,
              "saveFingerprint": saveFingerprint
          }
          this.validateOtp(otpValidateParams)
        }
      },
      async cancelVerification() {
        if(this.flowType != 'forgot_password'){
        await this.$store.dispatch('cvsstepup/cancelVerification')
        }
        this.$store.dispatch('cvsstepup/resetState')
        this.$store.dispatch('forgotflow/resetState')
        // this.$router.go(-3)
        if(this.flowType === 'forgot_username')
          this.$router.push({ name: 'AMForgotUsrnmComponent'}).catch(err => {throw err})
        else if(this.flowType === 'forgot_password') {
          this.updateOtpSent(false)
          // await this.initializeStepup({ challengeMethod: 'OTP' })
          // this.updateSelectedDevice(this.defaultSelectedDevice)
          this.updateSelectedDevice(this.lastSelectedDevice)
          this.updateOTPAlert([])
        }
          // this.$router.push({ name: 'AMForgotPwdComponent'}).catch(err => {throw err})
        else if(this.flowType === 'POST_AUTH_FLOW')
          this.updateHideOtpValidateComponent(true)
      },
      dontHaveOTPAccess() {
        $("#noAccessToOtpModal").modal({
          backdrop: "static"
        });
        this.updatecantrcvLink(true)
      },
      getOAuthClass(className = 'oauth-flow', subClass = '') {
        return className + ' ' + subClass;
      },
      getHostName(url) {
          var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
          if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
          } else {
            return null;
          }
      },
      continueButtonChange() {
      Vue.nextTick(() => {

      })
    },
    otpvalidation() {
      this.otpCode = this.otpCode.replace(/\D/g, '')
    },
    observePasswordField() {
      const passwordField = this.$refs.otpRef;
      //added MutationObserver to check if 'type' attrbute of input feild has changed
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if(mutation.attributeName === 'type' && passwordField.type !== 'password') {
            passwordField.type = 'password'
          }
        })
      })
      const config = { attributes: true };
      this.observer.observe(passwordField, config);

      passwordField.addEventListener('input', () => {
        if (passwordField.type !== 'password') {
          passwordField.type = 'password';
        }
      })
    },
      ...mapActions('cvsstepup', [
                'validateOtp',
                'unableToReceiveOtp',
                'sendOtpToDevice',
                'resendOtpToDevice',
                'updatecantrcvLink',
                'updateHideOtpValidateComponent',
                'updateVerificationMode',
                'updateSelectedDevice',
                'updateOtpSent',
                'initializeStepup',
                'updateOTPAlert'
            ]
        ),
      ...mapActions('forgotflow', [
                'updateUserVerified',
                'setFFTransactionId',
                'setFFUsername',
                'getUsername'
            ]
        )
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    dialogMsg: function (newVal) {
      console.log(newVal)
    },
    otpValidationError: function(newVal) {
      if(newVal) {
        this.otpValidationInputClass = "vz-error"
        this.otpCode = ''
      }
    },
    currentStep: function(newVal) {
      if(newVal === 'OTP_VALIDATED' && this.flowType === 'forgot_password') {
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.setFFUsername(this.username)
        this.$router.push({ name: 'AMForgotPwdComponent'})
      } else if(newVal==='OTP_VALIDATED' && this.flowType==='forgot_username') {
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.getUsername(this.email)
        // redirect user back to login page
        this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
      }
    },
    /*selectedOtpDevice: function(newVal, oldVal) {
      if(oldVal.deviceID != newVal.deviceID && this.resendPasscodeToSelectedDevice) {
        this.sendOtpToDevice(newVal.deviceID)
        this.resendPasscodeToSelectedDevice = false
        this.showResendOtpLink = true
      }
    } */
  },
  computed: {
      ...mapGetters('cvsstepup', [
      'dynamicErrorClasses',
      'fingerprintSaved',
      'sqaDisabled',
      'isPwReset',
      'alerts',
      'dialogMsg',
      'dialogTitle',
      'submitLoader',
      'currentStep',
      'transactionId',
      'username',
      'flowType',
      'email',
      'otpValidationError',
      'selectedOtpDevice',
      'otpDeliveryMsg',
      'otpAlwaysOn',
      'cantrcvLink',
      'devices',
      // 'defaultSelectedDevice'
    ]),
    ...mapGetters('login', [
      'urlOnMount', 'gotoUrl'
    ]),
    otpSent: {
      get: function () {
        return this.$store.getters['cvsstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateOtpSent', newVal)
      }
    },
    dialog: {
      get: function () {
        return this.$store.getters['cvsstepup/dialog']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateShowDialog', newVal)
      }
    },
    resendPasscodeToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendPasscodeToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendPasscodeToSelectedDevice', newVal)
      }
    },
    continueDisabled() {
      return !this.otpSent || !this.otpCode 
    }
  }
}
</script>
<style scoped>

.div {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
}
.btn {
    border-radius: 36px !important;
}
.alert-warning {
	background-color: #ffbc3d;
	background-size: 35px 29px;
	color: #000;
	border-color: #ffbc3d;
}
.alert-info {
	background-color: #08c;
	background-size: 35px 29px;
	color: #fff;
	border-color: #08c;
}

.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
    opacity: .2 !important;
    margin-top: -2px !important;
}
.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
input {
  padding: 11px 0 11px 10px;
    border-radius: 0px;
    font-size: 14px;
    color: #000;
    border: 1px solid #d8dada;
    border-bottom: 1px solid #000;
    box-shadow: none;
    transition: border .4s;
    height: 36px;
}
.v-application .primary {
  background-color: #000 !important; 
  border-color: #000 !important;
}
.vz-error {
  border: #ed7000 1px solid;
  border-bottom: #ed7000 4px solid;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-ff-btn-width120 {
  width: 120px !important;
  height: 44px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  /* padding: 12px; */
  font-size: 16px;
}
@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow-mt12 {
    margin-top: 12px !important;
  }
  .oauth-flow {
    padding-right: 0 !important;
    margin-top: 12px !important;
  }
  .oauth-flow v-form.padleft0.col-xs-12 {
    padding-right: 0 !important;
  }
  .oauth-flow-pr0 {
    padding-right: 0 !important;
  }
  .oauth-flow-w-100 {
    width: 100% !important;
  }
  .vbg-ff-btn-width120 {
    width: 100% !important;
  }
  .oauth-flow-jcaptch {
    width: 100%;
    margin-bottom: 10px;
  }
  .oauth-flow-continue-btn {
    width: 100%;
    margin-bottom: 12px;
  }
  .oauth-flow-cancel-btn {
    width: 100%;
    margin-left: 0 !important;
  }
  .oauth-flow-pt16 {
    padding-top: 16px !important;
  }
  .oauth-d-flex {
    display: flex;
  }
  .oauth-flex1 {
    flex: 1;
  }
  .oauth-flow-pr0 button {
    width: 100%;
    margin-bottom: 10px;
  }
  .bx-tooltip {
    display: none;
  }
  .oauth-modal-button {
    width: 100%;
  }
  .padding-bottom-small {
    padding-bottom: 12px !important;
  }
  .remember-device {
    margin-right: 0;
  }
  .margin-top-large{
    margin-top: 32px !important;
  }
  .margin-bottom-medium {
    margin-bottom: 32px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .oauth-flow-continue-btn {
    width: 47%;
  }
  .oauth-flow-cancel-btn {
    width: 47%;
  }
  .secondary_white {
    float: right;
  }
  .oauth-flow-pr0 button {
    width: 47%;
  }
  .vbg-ff-btn-width120 {
    width: 47% !important;
  }

}
.vbg-input-field:focus {
  box-shadow: none !important;
}
.margin-top-90 {
  margin-top: 60px !important;
}
.margin-top-64 {
  margin-top: 48px !important;
}
@media (min-width: 1025px) {
  .margin-top-large {
    margin-top: 48px !important;
  }
  .vbg-input-field {
    width: 301px !important;
  }
  .margin-top-90 {
    margin-top: 90px !important;
  }
  .margin-top-64 {
    margin-top: 64px !important;
  }
  .padding-bottom-small {
    padding-bottom: 24px !important;
  }
  .vbg-btn-continue {
    /* width: 140px !important; */
    margin-right: 12px !important;
  }
  .vbg-btn-cancel {
    /* width: 150px !important; */
  }
  .margin-bottom-medium {
    margin-bottom: 16px !important;
  }
}
.pmicro {margin-top: 22px; margin-bottom: 22px;}
.disabled {
  background: #D8DADA !important;
}

.padding-bottom-small-2 {
  padding-bottom: 64px !important;
}
button:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}
@media (max-width: 425px) {
  .marbottom30 {
    margin-bottom: 44px !important;
  } 
}
@media (max-width: 467px) {
  .vbg-tooltip:before {
    transform: translateX(-11%) !important;
  }
}

@media (min-width: 468px) and (max-width: 1024px) {
  .vbg-tooltip:after {
    margin-top: 15px !important;
    transform: rotate(137deg) !important;
  }
}

@media (max-width: 1024px) {
  .vbg-tooltip {
    display: none !important; /*remove this line to show tooltip on mobile/tablet screens */
  }
  .vbg-tooltip {
    width: 18px !important;
  }
  .vbg-tooltip:before {
    left: 26px !important;
    top: 160% !important;
  }
  .vbg-tooltip:hover {
    top: -4px !important;
  }
  .vbg-tooltip-container {
    display: block;
  }
}

@media (min-width: 1025px) {
  .vbg-tooltip:hover {
    top: -8px !important;
  }
}
.vz-a {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
a.vz-a:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}
.remember-device {
  margin-right: 12px;
}

.vbg-tooltip:before {
  width: 260px !important;
  min-height: 108px !important;
}
.vz-input-field {
  border-radius: 0px !important;
   height: 44px;
}
</style>
