<template>
<div class="loaderCss"></div>
</template>

<script>
export default {
  name: "CustomLoaderComponent",
  components: {},
};
</script>

<style scoped>
.loaderCss::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  cursor: progress;
  animation: rotate 4s linear infinite, timer 128s linear;
  background: none;
  border: solid 4px #000;
  border-top-color: #fff;
  border-radius: 32px;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes timer {
  0% {
  }

  33% {
    border-right-color: #fff;
  }

  66% {
    border-bottom-color: #fff;
  }

  100% {
    border-bottom-color: #fff;
    border-right-color: #fff;
  }
}
</style>
