<template>
    <div>
    <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'ContainerComponent',
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style scoped>
.container {
margin: 0 auto; max-width: 1280px; position: relative;
}

</style>
