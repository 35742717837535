var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.showsuccessMsg ||
    _vm.stepupVerified ||
    _vm.showerrorMsg ||
    _vm.showinfoMsg ||
    _vm.showwarnMsg
  )?_c('div',{staticClass:"vbg-ff-messagesInfo",class:{
    success: _vm.showsuccessMsg || _vm.stepupVerified,
    error: _vm.showerrorMsg,
    information: _vm.showinfoMsg,
    warning: _vm.showwarnMsg,
  }},[_c('div',{staticClass:"vbg-ff-msg-container"},[_c('div',{staticClass:"vbg-msg-banner-container"},[_c('div',{staticClass:"vbg-msg-banner-img"},[_c('div',{staticClass:"messageImage",class:{
            successMessageImage: _vm.showsuccessMsg || _vm.stepupVerified,
            newErrorMessageImage: _vm.showerrorMsg,
            newInfoMessageImage: _vm.showinfoMsg,
            newWarningMessageImage: _vm.showwarnMsg,
          }})]),_c('div',{staticClass:"vbg-msg-banner-message"},[(_vm.showsuccessMsg)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.successMsg)}}):_vm._e(),(_vm.showerrorMsg)?_c('p',{staticClass:"color-black",domProps:{"innerHTML":_vm._s(_vm.errorMsg)}}):_vm._e(),(_vm.showinfoMsg)?_c('p',{staticClass:"color-black",domProps:{"innerHTML":_vm._s(_vm.infoMsg)}}):_vm._e(),(_vm.showwarnMsg)?_c('p',{staticClass:"color-black",domProps:{"innerHTML":_vm._s(_vm.warnMsg)}}):_vm._e(),(
            _vm.stepupVerified && !_vm.showinfoMsg && !_vm.showerrorMsg && !_vm.showsuccessMsg
          )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.resetPwdMsg)}}):_vm._e()]),_c('div',{staticClass:"vbg-msg-banner-close-icon"},[(_vm.showsuccessMsg || _vm.stepupVerified)?_c('img',{staticClass:"closeMessageImg close-icon-img",attrs:{"src":require("@/common/assets/styles/corp/images/close.png")},on:{"click":function($event){return _vm.resetAllMessages()}}}):_c('img',{staticClass:"closeMessageImg close-icon-img",attrs:{"src":require("@/common/assets/styles/corp/images/close_black2.png")},on:{"click":_vm.resetMessages}})])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }