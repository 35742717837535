<template>
  <div>
    <div class="row no-gutters" v-if="showsuccessMsg" style="margin-top: 20px">
      <div class="col-12">
        <div class="message-success">
          <a class="col-xs-1 success-icon" style="padding:0px;"></a>

          <p>{{successMsg}}</p>

          <a class="col-xs-1 pull-right close-icon" @click="resetMessages" style="padding:0px;"></a>
        </div>
      </div>
    </div>
    <div class="row no-gutters" v-if="showerrorMsg" style="margin-top: 20px">
      <div class="col-12">
        <div class="message-error">
          <a class="col-xs-1 info-icon" style="padding:0px;"></a>
          <p>{{errorMsg}}</p>
          <a class="col-xs-1 pull-right close-icon" @click="resetMessages" style="padding:0px;"></a>
        </div>
      </div>
    </div>
    <div class="row no-gutters" v-if="showinfoMsg" style="margin-top: 20px">
      <div class="col-12">

        <div class="message-info">
          <a class="col-xs-1 info-icon" style="padding:0px;"></a>
          <p>{{infoMsg}}</p>
          <a class="col-xs-1 pull-right close-icon" @click="resetMessages" style="padding:0px;"></a>
        </div>
      </div>
    </div>
    <div class="row no-gutters" v-if="showwarnMsg" style="margin-top: 20px">
      <div class="col-12">

        <div class="message-warn">
          <a class="col-xs-1 warn-icon" style="padding:0px;"></a>
          <p>{{warnMsg}}</p>
          <a class="col-xs-1 pull-right close-icon" @click="resetMessages" style="padding:0px;"></a>
        </div>
      </div>
    </div>
     <div class="row no-gutters" v-if="stepupVerified && !showinfoMsg && !showerrorMsg && !showsuccessMsg" style="margin-top: 20px">
       <div class="col-12">
        <div class="message-success">
          <a class="col-xs-1 success-icon" style="padding:0px;"></a>

          <p>Your one-time passcode was successfully verified.</p>
          </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MessagesComponent",
  methods: {
    ...mapActions("profile", ["resetMessages"]),
  },

  computed: {
    ...mapGetters("profile", [
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "successMsg",
      "showsuccessMsg",
      "stepupVerified"
    ]),
  },
};
</script>
<style scoped>
.alert-success {
  background-color: #00ac3e;
  background-size: 35px 29px;
  color: #fff;
  border-color: #00ac3e;
}
@media (min-width: 280px) and (max-width: 319px) {
  .message-success p, .message-error p, .message-info p, .message-warn p {
    max-width: 145px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .message-success p, .message-error p, .message-info p, .message-warn p {
    max-width: 190px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 361px)  and (max-width: 419px) {
  .message-success p, .message-error p, .message-info p, .message-warn p {
    max-width: 230px  !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 420px) and (max-width: 458px) {
  .message-success p, .message-error p, .message-info p, .message-warn p {
    max-width: 280px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 458px) and (max-width: 530px) {
  .message-success p, .message-error p, .message-info p, .message-warn p {
    max-width: 300px !important;
    margin-right: 0px !important;
  }
}
</style>