<template>
  <!-- <div class="row navbar navbar-inverse footerCopyright" style="width: 80%; margin: 0 auto;">
    <div class="col-5">
      <img class="padleft15 img-responseive pull-left" :src="require('@/common/assets/styles/corp/images/vzlogo-white.png')" style="margin-left: 10px;">
    </div>
    <div class="col-6" style="padding-left: 70px;">
      <span>© {{year}} Verizon | <a class="tcFooterLink" href="https://www.verizon.com/about/terms-conditions/overview">Terms & Conditions</a></span>
    </div>
    <div class="col">
    </div>
  </div> -->
  <div id="vz-gf20" class="simpleLogin"></div>
</template>
<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    var globalNav = document.createElement("script");
    globalNav.setAttribute(
      "src",
      "https://www.verizon.com/business/ngnav/smb.js"
    );
    globalNav.setAttribute("async", "async");
    document.head.appendChild(globalNav);
  },
};
</script>
<style >
.simpleLogin .gnav20 .gnav20-footer-level-two {
  border-top: none !important;
}
</style>
