<template>
  <BlockUI v-if="submitLoader" :url="url"></BlockUI>
  <div v-else-if="!submitLoader">
    <div class="vbg-ff-title-container">
      <h1 class="vbg-rsp-title-h1">
        {{ $t("message.forgot_pd_general_title") }}
      </h1>
    </div>
    <div style="height: 40px">
      <p>{{ $t("message.pwd_reset_previous_pds") }}</p>
    </div>
    <!-- <ValidationObserver v-slot="{ handleSubmit,invalid }"> -->
    <div class="vbg-ff-form-container">
      <form @submit.prevent="onSubmit()">
        <div>
          <div>
            <!-- <ValidationProvider
                name="currentPassword"
                rules="Passwordrequired"
                v-slot="{ errors ,classes }"
              >
                <div class="row form-group no-gutters">
                  <div class="col-12">
                    <label class="labeltiny" for="currentPassword">Current password*</label>
                    <input
                      v-model="currentPassword"
                      class="form-control form-fields"
                      type="password"
                      :class="classes"
                      id="currentPassword"
                      name="currentPassword"
                      aria-required="true"
                      placeholder="********"
                      maxlength="20"
                    >
                    <span
                      id="currentPassword-help-block"
                      class="help-block para2 my-6"
                    >{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider> -->
          </div>
        </div>
        <PasswordComponent
          @child-passwordBlkList="passwordBlkListValue"
          @child-newpassword="newpasswordValue"
          @child-confirmpassword="confirmpasswordValue"
          @child-disablePrimaryButton="disabledPrimaryButtonValue"
        ></PasswordComponent>
        <!-- <div>
          <div>
            <button
              :disabled="!passwordBlkList"
              :class="{ 'inactive': !passwordBlkList}"
              class=""
              type="submit"
              id="btn_update"
            >
              <span id="sp_sendcode">Create password</span>
            </button>

            <button
              class=""
              type="reset"
              @click="cancel()"
              id="btn_back"
            >
              <span id="sp_sendcode">Cancel</span>
            </button>
          </div>
        </div> -->
        <div class="vbg-ff-form-container-button-group">
          <button
            class="btn vbg-ff-btn-continue vbg-ff-btn-width200"
            :class="disabledPrimaryButton == 'disabled' ? 'disabled' : false"
            style=""
            type="submit"
            :disabled="disabledPrimaryButton == 'disabled' ? true : false"
            ref="submitButton"
            id="preauth_changepassword"
            name="preauth_changepassword"
          >
            Change password
          </button>
          <button
            class="btn vbg-ff-btn-cancel vbg-ff-btn-width200"
            @click="cancel()"
            style=""
            type="button"
            name="preauth_cancelpassword"
            id="preauth_cancelpassword"
          >
            Cancel
          </button>
        </div>
        <div style="margin-bottom: 80px"></div>
      </form>
    </div>
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessagesComponent from "@/profile/components/core/MessagesComponent";
import PasswordComponent from "@/profile/components/core/PasswordComponent";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import commonMixin from "@/common/mixin/commonMixins";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../public/adobeTagging";
export default {
  name: "ResetPasswordComponent",
  data() {
    return {
      newpassword: "",
      confirmpassword: "",
      url: loadingImage,
      passwordBlkList: false,
      //url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      showContent: true,
      disabledPrimaryButton: false,
    };
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Profile:Reset password");
  },
  components: {
    PasswordComponent,
    MessagesComponent,
  },
  methods: {
    newpasswordValue: function (params) {
      this.newpassword = params;
    },
    confirmpasswordValue: function (params) {
      this.confirmpassword = params;
    },
    passwordBlkListValue: function (params) {
      this.passwordBlkList = params;
    },
    disabledPrimaryButtonValue: function (params) {
      console.log("disabledPrimaryButtonValue params", params);
      this.disabledPrimaryButton = params;
    },
    ...mapActions("profile", ["updatePassword", "getPassswordBlackList"]),
    moveover() {
      $("#PassInfo").popover({ trigger: "hover focus" });
    },

    onSubmit() {
      let payload = {
        uuID: this.uuID,
        password: this.newpassword,
        confirmPassword: this.confirmpassword,
      };

      if (!this.passwordBlkList) {
        this.updatePassword(payload);
      }
    },
  },
  computed: {
    ...mapGetters("profile", [
      "uuID",
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "gotoSQA",
      "gotoOtp",
      "gotoOtpOvw",
      "gotoPassSet",
      "submitLoader",
      "firstName",
      "lastName",
    ]),
  },
  mounted() {
     this.getPassswordBlackList();
    // Rendering the component based on uuID . So it will prevent the route to render in other tab without ID without id.
    console.log("this.uuID..", this.uuID);
    if (this.uuID === "") {
      this.showContent = false;
    }
    //  this.readFormInputs('pwd_reset_component')
    this.changeContextBiocatch('preauth_setpassword_page')
  },
  watch: {
    gotoSQA: function (newVal) {
      if (newVal) {
        this.$router.push({ path: "setsqa" });
      }
    },
    gotoOtp: function (newVal) {
      if (newVal) {
        this.$router.push({ path: "addotpdevice" });
      }
    },
    gotoOtpOvw: function (newVal) {
      if (newVal) {
        this.$router.push({ path: "otpsummary" });
      }
    },
    gotoPassSet: function (newVal) {
      if (newVal) {
        this.$router.push({ path: "resetpassword" });
      }
    },
  },
};
</script>
<style scoped>
/* .passErrormsg1 {
  color: rgb(205, 4, 11);
  font-weight: bold;
}

.passErrormsg2 {
  color: btn_primary_black;
  font-weight: bold;
}
.matched {
  text-decoration: line-through;
}
li{
  list-style-type: disc;
 
}
@media  (min-width: 350px) and ( max-width: 550px){
  .setup-secure-profile{
  
  }
  .pswdCriteria{
    margin-left: -10px;
  }
}
@media  (min-width: 768px) and ( max-width: 1024px){
  .setup-secure-profile {
    width: 88% !important; 
  }
  .createPwsdButton{
    margin-right:15px;
  }
}
@media (max-width: 1024px) {
  .row {margin-left: 0 !important; margin-right: 0 !important;}
  .oauth-button {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .createPwsdButton {
    margin-bottom: 16px;
  }
  .oauth-row {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .oauth-mw-100 { max-width: 100% !important; }
  .oauth-plr0 { padding-left: 0 !important; padding-right: 0 !important}
  .oauth-p0 { padding: 0 !important; }
  .oauth-flex1 { flex: 1; }
  .setup-secure-profile {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .col-xs-12 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .oauth-button {
    width: 47% !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }
} */
/* ******************************* */
/* modified css  for desktop view*/
.vbg-ff-form-container {
  margin-top: 48px !important;
}
.disabled {
  background-color: #d8dada !important;
}
.vbg-ff-form-container-button-group {
  margin-top: 88px;
}
/* ******************************************** */
@media (min-width: 769px) and (max-width: 1024px) {
  .vbg-ff-form-container {
    margin-top: 32px !important;
  }
  .vbg-ff-form-container-button-group {
    margin-top: 72px !important;
  }
  .vbg-ff-btn-width200 {
    width: 47% !important;
  }
}
@media (max-width: 768px) {
  .vbg-ff-form-container {
    margin-top: 32px !important;
  }
  .vbg-ff-btn-width200 {
    width: 100% !important;
  }
  .vbg-ff-btn-cancel {
    /* margin-bottom: 12px; */
    margin-top: 12px;
  }
  /* .vbg-ff-form-container-button-group {
    display: flex;
    flex-direction: column-reverse;
  } */
  .vbg-ff-form-container-button-group {
    margin-top: 72px;
  }
}
</style>
