<template>
  <div class="clearfix padding-top-large padding-bottom-xlarge padding-left-small margin-left-xlarge header">
    <div class="col-xs-11 col-sm-6">
       <img class="hlogo" :src="require('@/common/assets/styles/corp/images/vzlogoLarge.png')" />
    </div>  
  </div> 
</template>
<script>
import {iamvar_serverUrl} from '../../../public/const.js'
import {mapGetters } from "vuex";
import commonMixin from '@/common/mixin/commonMixins';
export default {
  name: 'Header',
  components: {
  },
  mixins: [commonMixin],
  data () {
    return {
    }
  },
  methods: {
    getLogo() {
      return iamvar_cdnUrl+iamvar_imgSourceContext+'/business/login/includes/img/vzlogoLarge.21ecaab0.png'
    }

  }
}
</script>
<style scoped>
.header {
  border-bottom: 1px solid #e4e5e3 !important;
}
.hlogo { display: block; float:left; width: 160px; }
</style>

@click.native="resetMessages"