<template>
  <div class="vbg-ff-main-container">
    <div class="error-container" :style="containerStyle">
      <h1 class="error-title" :style="titleStyle">{{ title }}</h1>
      <p
        class="error-description"
        :style="descriptionStyle"
        v-html="description"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorMessage",
  props: [
    "title",
    "description",
    "containerStyle",
    "titleStyle",
    "descriptionStyle",
  ],
  components: {},
  data() {
    return {
    }
  }
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.error-container {
  margin: 24px auto;
  border: 1px solid #000;
  /*height: 264px;*/
  width: 464px;
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 64px 40px;
}
.error-title {
  color: #000000;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 12px;
}
.error-description {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.vbg-ff-main-container {
  margin-bottom: 80px !important;
}
/* MOBILE & TABLET CSS HERE */
@media(max-width: 1024px) {
  .error-description {
    font-size: 16px;
    line-height: 20px;
  }
  .error-title {
    font-size: 25px;
    line-height: 24px;
  }
  .error-container{
    padding: 48px 16px;
    margin: 16px auto;
  }
  .vbg-ff-main-container {
    margin-bottom: 100px !important;
  }
}

/* MOBILE CSS FOR SESSION EXPIRED POP UP 
 all this styles shares common with link session pop too
*/
@media(max-width: 525px) {
  .error-container {
    width: 100%;
  }
}
</style>

<style>
.underline {
  text-decoration: underline !important;
}
.underline:focus {
  outline: 1px dashed #000;
  outline-offset: 1px;
}
</style>
