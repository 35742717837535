<template>
    <v-wait for="request_to_complete" class="full-height">
        <template slot="waiting">
            <LoaderComponent></LoaderComponent>
        </template>
        <div v-if="buildInfo">
            {{ buildInfo }}
        </div>
    </v-wait>
</template>
<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
export default {
  name: 'BuildInfo',
  components: {
      LoaderComponent
  },
  data () {
    return {
        context: null
    }
  },
  mounted () {
    this.context = window.location.href
    this.getBuildInfo(this.context)
  },
  methods: {
      ...mapWaitingActions('general', {
        getBuildInfo: 'request_to_complete'
      })
  },
  computed: {
      ...mapGetters('general', [
      'buildInfo'
    ])
  }
}
</script>
<style scoped>
</style>
