import axios from 'axios'
import {iamvar_serverUrl} from '../../../public/const.js'
export const playrest = axios.create({
  baseURL: iamvar_serverUrl + iamvar_iamApiRoute
})
export const iamPostAuthPlayrest = axios.create({
  baseURL: iamvar_serverUrl + iamvar_iamApiPostAuthRoute
})
export const cvsplayrest = axios.create({
  baseURL: iamvar_serverUrl + iamvar_cvsApiRoute
})
// export {playrest}
