<template>
      <div id="modal-popup" class="modal fade in">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <h3 class="mt-0">Are you sure you want to delete?</h3>
            <a data-dismiss="modal" class="pull-right close-icon-popup vbg-rsp-close-icon-popup" @click="cancelDeleteDevice()"></a>
           <p class="spacing05">You are about to delete {{deviceToBeDelete}} as a verification delivery method.</p>
           <div class="modal-popup-btn-box">
           <button
              type="submit" data-dismiss="modal"
              class="btn vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05"
              @click="conformDeleteDevice('deleteOTPDevice')"
            >
              <span>Delete</span>
            </button>
            <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05" data-dismiss="modal" @click="cancelDeleteDevice()">
              <span>Cancel</span>
            </button>
          </div> 
          </div>
        </div>
      </div>
   
   
  
 
    
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  import MessagesComponent from '@/profile/components/core/MessagesComponent'
  import commonMixin from '@/common/mixin/commonMixins';
  export default {
    name: "DeleteOTPComponent",
    mixins: [commonMixin],
    data() {
      return {
      deviceToBeDelete:'',
      whichRealm: true
      }
    },
    methods: {
     ...mapActions("profile", [
       
        'deleteOTPDevice',
        'updateTargetUrl'
        ]),
  
    conformDeleteDevice() {
      
      let payload = {
        uuID: this.uuID,
        otpDeviceList: [
          {
            guid: this.delSelectedDevice,
            otp_type: this.deviceTypeToBeDelete
          },
        ],
      };

      this.deleteOTPDevice(payload);
      this.$router.push({ path: 'overview', query : { target: this.logOutGotoUrl}});
    },
    cancelDeleteDevice(){
      this.$router.push({ path: 'overview', query : { target:this.logOutGotoUrl}});
    }
    
    },
    computed: {
    ...mapGetters("profile", [
      "logOutGotoUrl"
    ]),
    },

    mounted() {

      if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        var iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
      }
      // let params = new URLSearchParams(window.location.search)
      this.updateTargetUrl(this.getParameterByName("target"))
      this.$store.commit('profile/setAttributedUpdated', "deleteOTP")
      this.deviceToBeDelete =  window.sessionStorage.getItem('deviceToBeDelete')
      this.delSelectedDevice =  window.sessionStorage.getItem('delSelectedDevice')
      this.deviceTypeToBeDelete = window.sessionStorage.getItem('deviceTypeToBeDelete')
      this.uuID = window.sessionStorage.getItem('uuid')
       $('#modal-popup').modal({show:true,  backdrop: "static"});
      
      
     
    }
};
</script>

<style scoped>
#modal-popup {
  width: 100% !important;
  position: absolute !important;
}

.modal-dialog {
  width: 560px;
  height: 308px;
  margin: 0 auto !important;
  position: relative;
  top: 25%;
}

.modal-content {
  width: 560px;
  height: 308px;
  padding: 48px !important;
}

#modal-popup h3 {
  margin-bottom: 24px;
  font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif !important;
}

#modal-popup p {
  margin-bottom: 72px;
}
.close-icon-popup {
  top: -103px !important;
}

  .textDesc,
  .edit-link {
    display: inline-block;
    padding-left: 0px;
  }

  td,
  th {
    border: 0px;
    text-align: left;
    padding: 8px;
  }
  .tb-profile{
    width: 100%;
    margin-bottom:50px
  }
  .tb-profile td{
    margin-right: 50px;
  }
.view-otp-table{
  width: 100%;
  margin-bottom: 100px;
}
.vbg-ff-btn-width140 {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}
@media  (min-width: 350px) and ( max-width: 550px){
  .setup-secure-profile {
      width: 95% !important;
  }
}
@media (max-width: 442px) {
  .vbg-rsp-close-icon-popup {
    margin-top: 48px !important;  
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 48px 16px !important;
  }
  .vbg-rsp-close-icon-popup {
    left: 0px !important;
    margin-top: 62px !important;
    margin-right: 0px !important;
  }
  .modal-popup-btn {
    height: 44px !important;
    line-height: 18px;
    font-size: 16px;
    padding: 12px;
    text-align: center;
  }
}

@media  (min-width: 768px) and ( max-width: 1024px){
 .setup-secure-profile {
      width: 88% !important;
  }
}
@media (min-width: 280px) and (max-width: 768px) {
  .modal-dialog{
    width: 100%;
    height: 100%;
  }
  .modal-content{
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    top: 0;
  }
}
  
</style>