<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
      <ErrorMessage :title="title" :description="des"></ErrorMessage>
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>

<script>
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import Header from "@/vbgauth/components/LegalHeader";
import { mapGetters, mapActions } from "vuex";
import ErrorMessage from "@/common/components/ErrorMessage";
import translations from "@/common/assets/json/messages1.json";
export default {
  name: "SessionExpiredError",
  data() {
    return {
      title: translations.en.message['enhance_security_session_expired_title'],
      des: translations.en.message['enhance_security_session_expired_description'],
      whichRealm: true,
    };
  },
  components: {
    Footer,
    Header,
    ErrorMessage,
  },
  methods: {},
  computed: {},
  mounted() {
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      let iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
  },
  watch: {},
};
</script>
<style scoped>
</style>