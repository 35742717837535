<template>
  <div v-if="showContent">
    <v-wait for="request_to_complete">
    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
    <div v-else-if="!submitLoader">
      <!-- <div class="row mx-0">
      <div class="col-12 mx-0 px-0">
        <p v-if="!migContent && this.whichRealm" class="martop60 h2">Welcome {{firstName}} {{lastName}},</p>
        <p v-if="!migContent && !this.whichRealm" class="martop10 h1 marbottom10">Welcome <b>{{firstName}} {{lastName}}</b>,</p>
        <h1 v-if="!migContent" class="marbottom10 h2">Let's build your security profile</h1>
        <h1 v-if="migContent" class="marbottom10">Set up your secret question</h1>
        <p v-if="!migContent && this.whichRealm" class="para2 marbottom40">
        Your security profile is used to identify you and help you recover forgotten credentials when needed.
        </p>
        <p v-if="!migContent && !this.whichRealm" class="para2 marbottom10"> <br />
        Your security profile is used to identify you and help you recover forgotten credentials when needed.
        </p>
        <MessagesComponent></MessagesComponent>
        <hr class="marbottom10 mt-0" />
        <p class="marbottom40 req-msg">All fields are required</p> <br v-if="!this.whichRealm" /><br v-if="!this.whichRealm" />
        <h3 v-if="!migContent" class="para1 marbottom40" style="display: inline-block;font-size: 18px;">Set up your secret question</h3>
        <h3 v-if="migContent" class="para1 marbottom40" style="display: inline-block;font-size: 18px;">Due to a recent system enhancement, we need you to set up one secret question and answer which you can use going forward.</h3>
        <ul class="para2 marbottom30" style="margin-left: 20px;">
          <li>Answers are not case sensitive</li>
           <li>Must be from 3-40 characters</li>
            <li>Only letters (A-Z), numbers (0-9), spaces and periods are supported</li>
             <li>Choose an answer that is memorable and not easy to guess</li>
        </ul>
      </div> -->
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">
          {{ $t("message.preauth_sqa_tittle") }}
        </h1>
      </div>
      <div class="vbg-rsp-des">
        <p>{{ $t("message.preauth_sqa_description") }}</p>
      </div>
      <div class="incom-prof-cls" >
        <div class="vbg-rsp-title-h2"> {{ $t("message.incomplete_profile_tittle") }}</div>
        <p style="font-size: 14px; letter-spacing: 0; line-height: 16px;">{{ $t("message.incomplete_profile_desc") }}</p>
    </div>
    <div class="vbg-rsp-form-container">
    <ValidationObserver ref="form" v-slot="{ }">
      <form @submit.prevent="onSubmit">
        <SQAComponent
          :isProfileComplete="isProfileComplete"
          @child-challengeQuestion="challengeQuestionValue"
          @child-challengeAnswer="challengeAnswerValue"
        ></SQAComponent>
        <ValidationProvider
          name="status"
          :rules="`${enableTC ? 'TCrequired' : ''}`"
          v-slot="{ errors }" 
          v-show="challengeQuestion && challengeAnswer">
        
          <div  v-show="enableTC" class="chck-blok">
            <div style="display: flex; margin-bottom: 12px;">
              <input 
                type="checkbox"
                name="tc-modal"
                v-model="status"
                value="accepted"
                class="check_box_border"
                unchecked-value="not_accepted"
                :tabindex="secretQuestions.length+2"
                @keyup.enter="toggleCheckBox( 'enter')"
                ref="checkboxRef"
                id="ch"
                @click="toggleCheckBox(click)"
              />
              <label class="tc">
                I agree to the
                <a
                 @click="tcModal()"
                  id="tc-modal1" 
                  style="color: #000; text-decoration: underline; cursor: pointer;"
                  @keyup.enter="tcModal()"
                  :tabindex="secretQuestions.length+3"
                >Terms of Use</a>
              </label>
            </div>
            <span id="currentPassword-help-block" v-if="errors[0]" class="para2">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div>
          <div id="termModal" class="modal fade">
            <div class="modal-dialog modal-right">
              <div class="modal-content">
                <a
                  class="close-icon-container"
                  data-dismiss="modal"  @click="enable(false)"
                >
                  <img src="@/common/assets/styles/corp/images/close_black.png"/>
                </a>
                <popupComponent/>
                <div class="btn-block"> 
                    <button
                    type="button"
                    class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
                    :tabindex="secretQuestions.length+4"
                    @click="enable(true)"
                    id="accept"
                    data-dismiss="modal"
                    name="accept"
                  >
                    <span>Agree</span>
                  </button>
                  <button
                    type="button"
                    class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 spacing05"
                    :tabindex="secretQuestions.length+5"
                    @click="enable(false)"
                    id="cancel"
                    name="cancel"
                    data-dismiss="modal"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="continueBtn">
            <button
              type="submit"
              :tabindex="secretQuestions.length+6"
              :disabled="disableButton"
              ref="submitButtonRef"
              id="setsqa_continue"
              name="setsqa_continue"
              :class="disableButton ? 'vbg-ff-disabled' : ''"
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>  
  </div>
</v-wait>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapWaitingActions } from 'vue-wait'
import MessagesComponent from "@/profile/components/core/MessagesComponent";
import SQAComponent from "@/profile/components/core/SQAComponent";
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import popupComponent from "@/common/components/TermsPopupComponent"
import commonMixin from "@/common/mixin/commonMixins";

import $ from "jquery";
export default {
  name: "SetSQAComponent",
  data() {
    return {
      status: false,
      challengeQuestion: null,
      challengeAnswer: "",
      //url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
      whichRealm: true,
      showContent: true,
      // submitLoader: false,
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
      isProfileComplete: false
    };
  },
  components: {
    SQAComponent,
    MessagesComponent,
    popupComponent
  },
  methods: {
    challengeQuestionValue: function (params) {
      this.challengeQuestion = params;
    },
    challengeAnswerValue: function (params) {
      this.challengeAnswer = params;
    },

    ...mapActions("profile", ["displayLoginIntercept", "saveSQA", "updateSubmitLoder"]),
    enable(status) {
      if (status ) {
        this.status = true;
      } else {
        this.status = false;
      }
    },

    ...mapWaitingActions("profile", {
        displayLoginIntercept: 'request_to_complete'
    }),
    onSubmit() {
      this.updateSubmitLoder(true)
      var tcAccepted = false;
      if (this.status === true) {
        tcAccepted = true;
      }
      let payload = {
        uuID: this.uuID,
        sqaTO: [
          {
            id: this.challengeQuestion,
            pwd: this.challengeAnswer
          },
        ],
        tcAccepted: tcAccepted,
      };
      eventTracking('Set SQA Initited','');
      this.saveSQA(payload);
    },

    cancel() {
      this.$router.push({ path: "overview" });
    },
    tcModal() {
      $("#termModal").modal({
          backdrop: "static",
        });
         $('#tc-modal').prop('checked', false);
    },
    tcModal1() {
      $("#termModal").modal({
          backdrop: "static",
        });
         $('#tc-modal').prop('checked', true);
    },

    toggleCheckBox( selectiontype ) {
      if(selectiontype === 'enter') {
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else{
        this.$refs.checkboxRef.focus()
      }
      this.status = !this.status;
      
    }
  },
  computed: {
    ...mapGetters("profile", [
      "gotoSQA",
      "gotoOtp",
      "uuID",
      "enableTC",
      "migContent",
      "gotoOtpOvw",
      "firstName",
      "lastName",
      "sqa",
      "secretQuestions",
      "submitloader"
    ]),

    challengeQuestion: {
      get: function () {
        return this.$store.getters["profile/challengeQuestion"];
      },
      set: function (newVal) {
        this.$store.dispatch("profile/setChallengeQuestion", newVal);
      },
    },
    challengeAnswer: {
      get: function () {
        return this.$store.getters["profile/challengeAnswer"];
      },
      set: function (newVal) {
        this.$store.dispatch("profile/setChallengeAnswer", newVal);
      },
    },
    disableButton() {
      //return (this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestion != "Select" && this.enabletc ? this.status : "") ? false : true
      
      if(this.enableTC) {
        if(this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestion != "Select" && this.status) {
          return false
        }
      } else {
        if(this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestion != "Select") {
          return false
        }
      }

      return true
    }
  },
  mixins: [commonMixin],
  mounted() {
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      var iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    window.onpopstate = event => {
      console.log(event)
      if (window.location.pathname.includes('business/profile/preauth/intercept')) {
        history.back();
      }
    };
    // Rendering the component based on uuID . So it will prevent the route to render in other tab without ID without id.
    if(this.uuID === "") {
      this.showContent = false;
    }
    this.changeContextBiocatch('preauth_setsqa_page')
  },
  beforeDestroy() {
    $('.modal-backdrop').remove()
  },
  watch: {
    gotoSQA: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'setsqa' })
        }
      },
      gotoOtp: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'addotpdevice' })
        }
      },
      gotoOtpOvw: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'otpsummary' })
        }
      },
      migContent: function(newVal) {
        if(newVal) {
          this.title= "Set up your secret question"
          this.subTitle= "Due to a recent system enhancement, we need you to set up one secret question and answer which you can use going forward."
        }
      },
  },
};
</script>

<style scoped>
/* .btn-dark {
  color: #fff;
  background-color: black;
  border-color: #46b8da;
}

.modal-body {
  padding: 0 !important;
}
.modal-body p {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-bottom: 30px;
}
.modal-body a {
  overflow-wrap: break-word;
} 
.close-icon {
  background-size: 18px;
  background-position-x: -2%;
  background-position-y: 0px;
  width: 18px;
  height: 18px;
  padding: 0px;
  position: relative;
  left: 30px;
}
.modal-cancel {
  height: 42px;
  background-color: transparent;
  margin: 30px 0 60px 25px;
  border: 1px solid #000;
}
.modal-cancel span {
  margin: 0 26px 0 26px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19.2px;
  color: #000;
}
.modal-agree {
  height: 42px;
  background-color: #000;
  margin-bottom: 60px;
}
.modal-agree span {
  margin: 0 26px 0 26px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19.2px;
  color: #fff;
}
.modal-heading {
  margin-bottom: 50px;
  margin-top: 0px;
  height: 31px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 30.4px;
  font-weight: 700 !important;
}
.modal-sub-heading {
  margin-bottom: 50px;
  margin-top: 0px;
  height: 31px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 30.4px;
  font-weight: 700 !important;
}
li{
  list-style-type: disc;
  list-style-position: inside;
}
ol, ul {
  list-style: none;
  margin-left: 20px;
}
ol[type="a"],
.lower-alpha {
  list-style-type: lower-alpha;
}
.tc-cancel-button {
  margin: 20px 0 0 20px;
}
.close-icon-tc {
  margin: 0 !important;
  top: 0 !important;
  right: 0 !important;
  left: auto !important;
  cursor: pointer;
}
@media  (min-width: 350px) and ( max-width: 550px){
  .setup-secure-profile {
      width: 95% !important;
      margin-left: 7px !important;
  }
  .modal-dialog{
    margin: 0 auto;
    position: relative;
    top: 0;
    width: 98% !important;
    height: auto;
  }
  .modal-content{
    overflow: scroll;
    inline-size: min-content;
    padding: 0px !important;
    width: 100% !important;
  }
  .modal-body{
    padding: 0 !important;
    text-align: justify;
  }
  .tc-cancel-button {
    margin: 20px 0 0 0;
  }
}
.modal-content{padding: 20px !important};
@media  (min-width: 768px) and ( max-width: 1024px){
 .setup-secure-profile {
    width: 95% !important;
  }
   .modal-dialog{
    margin: 0 auto;
    position: relative;
    top: 0;
    width: 98% !important;
    height: auto;
  }
  .modal-content{
    overflow: scroll;
    padding: 0px !important;
  }
  .modal-body{
    padding: 0 !important;
    text-align: justify;
  }
  .modal-right {
    margin: 0 auto !important;
    width: 98% !important;
  }
  .tc-cancel-button {
    margin: 20px 0 0 20px;
  }
}
@media (max-width: 768px) {
  .marbottom40 {
    margin-bottom: 20px !important;
  }
  .setup-secure-profile {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .oauth-flow-c-button {
    width: 100% !important;
  }
  .tc-cancel-button {
    margin: 20px 0 0 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .tablet-c-button {
    width: 47% !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }
  .modal-right {
    margin: 0 auto !important;
    width: 98% !important;
  }
  
  .oauth-popup-b-agree {
    width: 47% !important;
  }
  .oauth-popup-b-cancel {
    width: 47% !important;
    float: right !important;
    margin-left: 0 !important
  }
}
modal for small screens
@media (max-width: 349px) {
  .modal-right {
    margin-left: 0 !important;
  }
}
@media (min-width: 551px) and (max-width: 767px) {
  .modal-right {
    margin: 0 auto !important;
    width: 98% !important;
  }
  .modal-content {
    width: 100% !important;
  }
}
@media(min-width: 1025px) {
  .modal-right {
    width: 600px !important;
    margin: 0 auto !important;
  }
  .modal-content {
    height: 450px !important;
    overflow: scroll !important;
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-open .modal {
    display: flex !important;
    align-items: center !important;
  }
} */
input[type="checkbox"] { 
  position: relative;
  left: 0em;
  height: 20px;
  width: 20px;
}
input[type="checkbox"] + label {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-family: VzNHGeDS-reg11;
  margin-bottom: 0px;
}
.check_box_border[type="checkbox"]:checked{
  accent-color: #000 !important;
}
#ch {
  margin: 0px 10px 0px 0px !important;
}
.close-icon-container {
  padding: 0px;
  position: absolute;
  right: 34px;
  top: 34px;
  height: 20px;
}
.modal-body {
  margin: 64px 41px 116px 40px !important;
  padding: 0 !important;
  height: calc(100% - 179px) !important;
}
.btn-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5);
  padding: 16px 16px 48px 40px!important;
}
.modal-heading {
  margin-bottom: 32px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px !important;
  font-weight: bold !important;
}
.modal-body p {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 32px;
  color: #000;
}
.modal-body a {
  overflow-wrap: break-word;
}
.modal-sub-heading {
  margin-bottom: 16px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px !important;
  font-weight: bold !important;
  text-align: justify;
}
li{
  list-style-type: disc;
}
ol, ul {
  list-style: none;
  margin-left: 20px;
}
ol[type="a"],
.lower-alpha {
  list-style-type: lower-alpha;
}
.modal-content {
  height: 100% !important;
  overflow: hidden !important;
  width: 100% !important;
  padding: 0px !important;
}
.modal-open .modal {
  display: flex !important;
  align-items: end;
  justify-content: end;
  padding: 0px !important;
}
.modal-dialog {
  margin: 0px !important;
  max-width: 739px;
  width: 100% !important;
  height: -webkit-fill-available !important;
}
.vbg-sp-btn-continue {
  margin-top: 64px !important;
}
.incom-prof-cls {
  margin: 48px 0px 32px;
}
.continueBtn {
  margin-top: 64px;
}
.chck-blok {
  margin-top: 48px;
}
.scrollable-content {
  padding-right: 58px;
  height: calc(100% - 66px) !important;
  overflow-y: scroll; 
  position: relative;
}
.vbg-rsp-des {
  color: #000000 !important;
  font-size: 14px; 
  letter-spacing: 0; 
  line-height: 16px;
}
.vbg-rsp-title-h2 {
  font-family: "VzNHGeDS-bold11",Arial,Helvetica,sans-serif !important;
}
@media  (min-width: 769px) and ( max-width: 1024px){
  .vbg-ff-btn-cancel  {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 1024px) { 
  .modal-body{
    padding: 0 !important;
    text-align: justify;
    height: -webkit-fill-available !important;
  }
  .modal-heading {
    /* margin-left: 16px;
    margin-top: 72px; */
    margin-bottom: 32px;
    font-size: 24px !important;
    line-height: 24px !important;
  }
  .modal-sub-heading {
    line-height: 20px !important;
    font-size: 20px;

  }
  .incom-prof-cls {
    margin: 32px 0px 24px;
  }
  .chck-blok {
    margin-top: 32px;
  }
}
@media (max-width: 768px) { 
  .close-icon-container { 
    right: 14px;
    top: 62px;
  }
  .btn-block {
    display: flex;
    /* -ms-flex-direction: column-reverse; */
    flex-direction: column-reverse;
    padding: 16px 16px 48px !important;
  }
  .modal-content { padding: 0px !important; }
  .modal-body{ margin: 72px 16px 168px !important; }
  .scrollable-content {
    padding-right: 8px;
    /* height: calc(100% - 220px) !important; */
  }
}
::-webkit-scrollbar {
  /* display: none; */
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #747676;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #D8DADA;
  border-radius: 10px;
  height: 375px;
}
</style>
