<template>
  <div class="footer footerCopyright">
    <div class="col-5">
      <img class="padleft15 img-responseive pull-left" :src="require('@/common/assets/styles/corp/images/vzlogo-white.png')">
    </div>
    <div class="col-6">
      <span>© {{year}} Verizon | <a class="tcFooterLink" href="https://www.verizon.com/about/terms-conditions/overview">Terms & Conditions</a></span>
    </div>
    <div class="col">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  components: {
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style scoped>
</style>
