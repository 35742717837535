<template>
  <div
    v-if="
      showsuccessMsg ||
      stepupVerified ||
      showerrorMsg ||
      showinfoMsg ||
      showwarnMsg
    "
    class="vbg-ff-messagesInfo"
    :class="{
      success: showsuccessMsg || stepupVerified,
      error: showerrorMsg,
      information: showinfoMsg,
      warning: showwarnMsg,
    }"
  >
    <div class="vbg-ff-msg-container">
      <div class="vbg-msg-banner-container">
        <div class="vbg-msg-banner-img">
          <div
            class="messageImage"
            :class="{
              successMessageImage: showsuccessMsg || stepupVerified,
              newErrorMessageImage: showerrorMsg,
              newInfoMessageImage: showinfoMsg,
              newWarningMessageImage: showwarnMsg,
            }"
          ></div>
        </div>
        <div class="vbg-msg-banner-message">
          <p v-if="showsuccessMsg" v-html="successMsg"></p>
          <p v-if="showerrorMsg" v-html="errorMsg" class="color-black"></p>
          <p v-if="showinfoMsg" v-html="infoMsg" class="color-black"></p>
          <p v-if="showwarnMsg" v-html="warnMsg" class="color-black"></p>

          <p
            v-if="
              stepupVerified && !showinfoMsg && !showerrorMsg && !showsuccessMsg
            "
            v-html="resetPwdMsg"
          ></p>
          <!--<b>Your one-time passcode was successfully verified.</b>-->
        </div>
        <div class="vbg-msg-banner-close-icon">
          <img
            v-if="showsuccessMsg || stepupVerified"
            class="closeMessageImg close-icon-img"
            src="@/common/assets/styles/corp/images/close.png"
            @click="resetAllMessages()"
          />
          <img
            v-else
            class="closeMessageImg close-icon-img"
            src="@/common/assets/styles/corp/images/close_black2.png"
            @click="resetMessages"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewErrorMessagesComponent",
  data() {
    return {
      resetPwdMsg: "<b class='bold75'>Your account has been verified</b>",
    };
  },
  methods: {
    ...mapActions("profile", ["resetMessages", "updateStepupVerified"]),
    resetAllMessages() {
      this.updateStepupVerified(false);
      this.resetMessages();
    },
  },
  mounted() {
    if (window.location.href.includes("modifypassword")) {
      this.resetPwdMsg += "<br/>Create a new password to continue.";
    }
  },
  computed: {
    ...mapGetters("profile", [
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "successMsg",
      "showsuccessMsg",
      "showwarnMsg",
      "stepupVerified",
    ]),
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.msg-main-container {
  width: 100%;
}
.vbg-msg-banner-container {
  display: flex;
}
.vbg-msg-banner-img {
  margin-right: 14px;
  /*padding-top: 5px;*/
}

.vbg-msg-banner-message {
  flex: 1;
}
.vbg-msg-banner-message p {
  font-family: "VzNHGeDS-reg";
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: -1px;
}
.color-black {
  color: #000;
}
.newInfoMessageImage {
  background-size: contain;
  background-repeat: no-repeat;
}

.close-icon-img {
  width: 13px;
  height: auto;
  position: relative;
  z-index: 9999;
  cursor: pointer !important;
}
.messageImage {
  width: 18px !important;
  height: 18px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
