var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vbg-ff-form-container-main"},[_c('div',{staticClass:"vbg-ff-form-container-main-group1"},[_c('label',{staticClass:"vbg-ff-input-label",attrs:{"for":"pwd"}},[_vm._v("New password")]),_c('div',{staticClass:"vbg-icon-input-group"},[((_vm.show1 ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userPwd),expression:"userPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.userPwd
              ? _vm.passwordInvalidClass() == 'orangeLoader'
                ? 'vbg-input-warning'
                : 'vbg-input-sucess'
              : '',attrs:{"id":"pwd","name":"pwd","autocomplete":"new-password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.userPwd)?_vm._i(_vm.userPwd,null)>-1:(_vm.userPwd)},on:{"input":_vm.checkPassword,"focus":function($event){return _vm.showSuggestionBox()},"blur":function($event){return _vm.hideSuggestionBox()},"change":function($event){var $$a=_vm.userPwd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.userPwd=$$a.concat([$$v]))}else{$$i>-1&&(_vm.userPwd=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.userPwd=$$c}}}}):((_vm.show1 ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userPwd),expression:"userPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.userPwd
              ? _vm.passwordInvalidClass() == 'orangeLoader'
                ? 'vbg-input-warning'
                : 'vbg-input-sucess'
              : '',attrs:{"id":"pwd","name":"pwd","autocomplete":"new-password","type":"radio"},domProps:{"checked":_vm._q(_vm.userPwd,null)},on:{"input":_vm.checkPassword,"focus":function($event){return _vm.showSuggestionBox()},"blur":function($event){return _vm.hideSuggestionBox()},"change":function($event){_vm.userPwd=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userPwd),expression:"userPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.userPwd
              ? _vm.passwordInvalidClass() == 'orangeLoader'
                ? 'vbg-input-warning'
                : 'vbg-input-sucess'
              : '',attrs:{"id":"pwd","name":"pwd","autocomplete":"new-password","type":_vm.show1 ? 'text' : 'password'},domProps:{"value":(_vm.userPwd)},on:{"input":[function($event){if($event.target.composing)return;_vm.userPwd=$event.target.value},_vm.checkPassword],"focus":function($event){return _vm.showSuggestionBox()},"blur":function($event){return _vm.hideSuggestionBox()}}}),_c('div',{class:_vm.userPwd
              ? _vm.passwordInvalidClass() == 'orangeLoader'
                ? 'vbg-warning-icon'
                : 'vbg-success-icon'
              : ''},[_c('img',{attrs:{"src":_vm.userPwd
                ? _vm.passwordInvalidClass() == 'orangeLoader'
                  ? _vm.warningIcon
                  : _vm.checkIcon
                : ''}})]),_c('div',{staticClass:"vbg-show-icon"},[(!_vm.show1 && _vm.userPwd)?_c('span',{staticClass:"vbg-rsp-link",on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v("Show")]):_vm._e(),(_vm.show1 && _vm.userPwd)?_c('span',{staticClass:"vbg-rsp-link",on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v("Hide")]):_vm._e()]),_c('div',{staticClass:"vbg-ff-input-success"},[(_vm.passwordInvalidClass() == 'greenLoader')?_c('span',[_vm._v("Password strength : "+_vm._s(_vm.pwdResetStrength))]):(
              _vm.passwordInvalidClass() == 'orangeLoader' &&
              _vm.userPwd &&
              _vm.invalidCharacters &&
              !_vm.enhanceFpFlow
            )?_c('span',[_vm._v("Please enter a valid password")]):(
              _vm.passwordInvalidClass() == 'orangeLoader' &&
              _vm.userPwd &&
              _vm.checkSequenceValidationError &&
              _vm.enhanceFpFlow
            )?_c('span',[_vm._v("Avoid sequences like abcd, 1234, AAAA, 1111.")]):(
              _vm.passwordInvalidClass() == 'orangeLoader' &&
              _vm.passwordBlkListValidation &&
              _vm.enhanceFpFlow
            )?_c('span',[_vm._v("Password is too easy to guess. Try another.")]):(
          _vm.passwordInvalidClass() == 'orangeLoader' && 
          _vm.userPwd && _vm.enhanceFpFlow && 
          !this.displaySuggestionBox
          )?_c('span',[_vm._v("Password doesn't meet all requirement")]):_vm._e()])])]),(_vm.displaySuggestionBox)?_c('div',{staticClass:"password-criteria-container"},[_c('div',{staticClass:"password-criteria"},[_c('div',[_c('span',{staticClass:"pas-str"},[_vm._v("Password strength : "+_vm._s(_vm.userPwd.length > 0 ? _vm.pwdResetStrength : ""))]),_c('div',{staticClass:"pwd-progress-bar"},[_c('div',{staticClass:"pwd-progress-bar-black",style:({ width: _vm.computedPwdProgress })})]),_vm._l((_vm.pwdReq),function(req,i){return _c('div',{key:i,staticStyle:{"display":"flex","height":"20px"}},[(req.required)?_c('div',{class:[
                _vm.pwdReqMatch[i].matched == 'matched'
                  ? 'vbg-tick-mark'
                  : 'unmatched-dot',
                req.type == 'cannot_contain_special_characters'
                  ? 'validationStyle'
                  : '',
              ]},[(_vm.pwdReqMatch[i].matched == 'matched')?_c('img',{attrs:{"src":_vm.checkIcon}}):_vm._e()]):_vm._e(),(req.required)?_c('div',{staticClass:"pwd-progress-bar-text",class:req.type == 'cannot_contain_special_characters'
                  ? 'martop18'
                  : ''},[_vm._v(" "+_vm._s(req.display_msg)+" ")]):_vm._e()])})],2)]),_c('div',{attrs:{"id":"pointer"}})]):_vm._e()]),(
      _vm.passwordInvalidClass() == 'greenLoader' ||
      (_vm.passwordInvalidClass() == 'orangeLoader' &&
        _vm.userPwd &&
        _vm.invalidCharacters &&
        !_vm.enhanceFpFlow) ||
      (_vm.passwordInvalidClass() == 'orangeLoader' &&
        _vm.userPwd &&
        _vm.checkSequenceValidationError &&
        _vm.enhanceFpFlow) ||
      (_vm.passwordInvalidClass() == 'orangeLoader' &&
        _vm.passwordBlkListValidation &&
        _vm.enhanceFpFlow)
    )?_c('div',{}):_vm._e(),_c('div',{staticClass:"vbg-ff-form-container-second-group"},[_c('label',{staticClass:"vbg-ff-input-label",class:_vm.passwordInvalidClass() == 'orangeLoader'? 'vbg-rsp-disabled-label' : '',attrs:{"for":"pwdConfirm"}},[_vm._v("Re-enter password ")]),_c('div',{staticClass:"vbg-icon-input-group"},[((_vm.show2 ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userReenteredPwd),expression:"userReenteredPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label' : 
          _vm.userReenteredPwd
            ? _vm.repasswordValidClass() == 'orangeLoader'
              ? 'vbg-input-warning'
              : 'vbg-input-sucess'
            : '',attrs:{"id":"pwdConfirm","name":"pwdConfirm","autocomplete":"new-password","disabled":_vm.passwordInvalidClass() == 'orangeLoader'? true : false,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.userReenteredPwd)?_vm._i(_vm.userReenteredPwd,null)>-1:(_vm.userReenteredPwd)},on:{"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"change":function($event){var $$a=_vm.userReenteredPwd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.userReenteredPwd=$$a.concat([$$v]))}else{$$i>-1&&(_vm.userReenteredPwd=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.userReenteredPwd=$$c}}}}):((_vm.show2 ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userReenteredPwd),expression:"userReenteredPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label' : 
          _vm.userReenteredPwd
            ? _vm.repasswordValidClass() == 'orangeLoader'
              ? 'vbg-input-warning'
              : 'vbg-input-sucess'
            : '',attrs:{"id":"pwdConfirm","name":"pwdConfirm","autocomplete":"new-password","disabled":_vm.passwordInvalidClass() == 'orangeLoader'? true : false,"type":"radio"},domProps:{"checked":_vm._q(_vm.userReenteredPwd,null)},on:{"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"change":function($event){_vm.userReenteredPwd=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userReenteredPwd),expression:"userReenteredPwd"}],staticClass:"vbg-rsp-input-field",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label' : 
          _vm.userReenteredPwd
            ? _vm.repasswordValidClass() == 'orangeLoader'
              ? 'vbg-input-warning'
              : 'vbg-input-sucess'
            : '',attrs:{"id":"pwdConfirm","name":"pwdConfirm","autocomplete":"new-password","disabled":_vm.passwordInvalidClass() == 'orangeLoader'? true : false,"type":_vm.show2 ? 'text' : 'password'},domProps:{"value":(_vm.userReenteredPwd)},on:{"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"input":function($event){if($event.target.composing)return;_vm.userReenteredPwd=$event.target.value}}}),(_vm.passwordInvalidClass() != 'orangeLoader')?_c('div',{class:_vm.userReenteredPwd
            ? _vm.repasswordValidClass() == 'orangeLoader'
              ? 'vbg-warning-icon'
              : 'vbg-success-icon'
            : ''},[_c('img',{attrs:{"src":_vm.userReenteredPwd
              ? _vm.repasswordValidClass() == 'orangeLoader'
                ? _vm.warningIcon
                : _vm.checkIcon
              : ''}})]):_vm._e(),_c('div',{staticClass:"vbg-show-icon",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''},[(!_vm.show2 && _vm.userReenteredPwd)?_c('span',{staticClass:"vbg-rsp-link",on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v("Show")]):_vm._e(),(_vm.show2 && _vm.userReenteredPwd)?_c('span',{staticClass:"vbg-rsp-link",on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v("Hide")]):_vm._e()]),(_vm.userReenteredPwd && _vm.repasswordValidClass() == 'greenLoader')?_c('div',{staticClass:"vbg-ff-input-success",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''},[_c('span',[_vm._v("Password match: Yes")])]):_vm._e(),(_vm.userReenteredPwd && _vm.repasswordValidClass() == 'orangeLoader')?_c('div',{staticClass:"vbg-ff-input-success",class:_vm.passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''},[_c('span',[_vm._v("Passwords do not match.")])]):_vm._e()])]),(
      (_vm.userReenteredPwd && _vm.repasswordValidClass() == 'greenLoader') ||
      (_vm.userReenteredPwd && _vm.repasswordValidClass() == 'orangeLoader')
    )?_c('div',{staticClass:"input-success-mobile"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }