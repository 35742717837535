<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-rsp-error-container">
      <NewErrorMessagesComponent></NewErrorMessagesComponent>
    </div>
    <div class="vbg-ff-content-container">
      <div class="vbg-rsp-root-container">
        <router-view></router-view>
      </div>
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>
<script>
//import Footer from "@/vbgauth/components/Footer";
//import Header from "@/vbgauth/components/Header";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import Header from "@/vbgauth/components/LegalHeader";
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import NewErrorMessagesComponent from "@/profile/components/core/NewErrorMessagesComponent";

export default {
  name: "RootComponent",
  components: {
    Header,
    Footer,
    NewErrorMessagesComponent,
  },
  data() {
    return {
      whichRealm: true,
    };
  },
  beforeDestroy() {
    if (this.$route.path.includes("preauth")) {
      //  this.destroyInterval();
    }
  },
  mixins: [commonMixin],
  mounted() {
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      let iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }

    // get gotoUrl from window.location if available
    // URLSearchParams not supported in IE
    // let params = new URLSearchParams(window.location.search)
    if (this.getParameterByName("goto")) {
      // assign goto in client library
      setGotoUrlInClientLib(this.getParameterByName("goto"));
    }

    this.readConfig("vbg_config");
    this.readFormInputs("pwd_reset_component");
    if (this.$route.path.includes("preauth")) {
      // this.getCheckCookie();
    }
  },
  watch: {
    // checkSession: function (newVal) {
    //   console.log("checkSession watch...",newVal)
    //   if (this.$route.path.includes("preauth") && !newVal) {
    //     this.$router.push({ name: "SessionExpiredError" });
    //   }
    // },
  },
  methods: {
    ...mapActions("profile", ["readFormInputs", "readConfig"]),
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
</style>
