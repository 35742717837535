<template>

  <b-container>
    <v-wait for="request_to_complete">
      <template slot="waiting">
          <LoaderComponent></LoaderComponent>
      </template>
      
   
   
    </v-wait>
  </b-container>

</template>
<script>
  
  import LoaderComponent from '@/common/components/LoaderComponent'
  import { mapWaitingActions } from 'vue-wait'
  import { mapGetters } from "vuex";
  import commonMixin from '@/common/mixin/commonMixins'
  export default {
    name: 'InterceptComponent',
    components: {
   
      LoaderComponent


    },
    mixins: [commonMixin],
    data() {
      return {
       
      }
    },
    methods: { 
      init() {
        // URLSearchParams not supported in IE
       // let params = new URLSearchParams(window.location.search)
        if(this.getParameterByName('goto')) {
          this.$store.dispatch('profile/updateGotoUrl', this.getParameterByName('goto'))
          setGotoUrlInClientLib(this.getParameterByName('goto'))
        }
      },
      ...mapWaitingActions("profile", {
        displayLoginIntercept: 'request_to_complete'
    })

    },
    computed: {
      ...mapGetters("profile", [

        'gotoSQA',
        'gotoOtp',
        'gotoOtpOvw',
        'gotoPassSet',
        'submitLoader'

      ]),
    },
    mounted() {
      this.init()
      this.displayLoginIntercept()

      // get gotoUrl from window.location if available
     // let params = new URLSearchParams(window.location.search)
      if(this.getParameterByName('goto')) {
        // assign goto in client library
        setGotoUrlInClientLib(this.getParameterByName('goto'))
      }

    },
    watch: {
      gotoSQA: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'setsqa' })
        }
      },
      gotoOtp: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'addotpdevice' })
        }
      },
      gotoOtpOvw: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'otpsummary' })
        }
      },
      gotoPassSet: function (newVal) {
        if (newVal) {
          this.$router.push({ path: 'resetpassword' })
        }
      }

    }

  } 
</script>
<style scoped>
</style>