<template>
  <div>
    <div id="vz-gh20"></div>
  </div>
</template>

<script>
export default {
  name: "LegalHeader",
  components: {},
  data() {
    return {
      componentName: "/",
    };
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    gnavdl = {
      bu: "smb",
      appid: "mybusinesslimited",
    };
  },
  mounted() {
    var header = document.createElement("script");
    header.setAttribute(
      "src",
      "https://www.verizon.com/business/ngnav/smb.js"
    );
    header.setAttribute("async", "async");
    document.head.appendChild(header);
    this.componentName = this.$route.name;
  },
};
</script>
<style scoped>
</style>
