<template>
  <BlockUI :url="url"></BlockUI>
    
</template>

<script>
import { mapActions } from "vuex";
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
export default {
  name: "ProcessLoaderComponent",
  data() {
    return {
      //url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
      
    };
  },
 
  components: {
    
   
  },
  methods: {
    
    ...mapActions("profile", [
     
      "CallcreateNewSession",
    ]),
    
   
   
      
  },
   
  computed: {
   
  },
  mounted() {  this.CallcreateNewSession()
  },
   
 
};
</script>

<style scoped>

</style>
