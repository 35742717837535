<template>
  <div>
     <!-- <ValidationProvider name="challengeQuestion" rules="SQrequired" v-slot="{ errors, classes }">
        <div class="row form-group no-gutters">
          <div class="col-12">
            <label class="labeltiny marbottom7" for="challengeQuestion">Select Secret question</label>
            <div class="row dropdown dropdown-toggle" id="drpdn" v-bind:class="{ open: showDropdown }" aria-haspopup="true" aria-expanded="false" @click="togle()">
              <div class="col-xs-11" style="padding: 3px 9px">
                <a id="dropdownMenu1" aria-haspopup="true" role="button">
                  <span>
                    <span>{{challengeQuestionText}}</span>
                  </span>
                </a>
              </div>
              <a class="col-xs-1 pull-right dropdown-arrow" style="padding:0px;"></a>
              <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1">
                <li v-for="question in secretQuestions" :key="question.id" role="presentation" v-bind:class="{ chosen: (question.question === challengeQuestion)}">
                  <a role="menuitem" style="height:40px" @click="selectQuestion(question)" :class="classes">
                  <a role="menuitem" @click="selectQuestion(question)" :class="classes">
                    <span>
                      <span>{{question.question}}</span>
                      <span class="sqaText">{{question.question}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
             <span
                  id="currentPassword-help-block"
                  class="help-block para2 my-6"
                >{{ selectboxError }}
             </span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="challengeAnswer" rules="SQArequired|minSQALen:3|maxSQALen:40|sqaClause" v-slot="{ errors, classes}">
        <div class="row form-group no-gutters">
          <div class="col-12">
            <label class="labeltiny" for="Security_answer">Your answer</label>
            <input type="password" id="challengeAnswer" name="challengeAnswer" placeholder="********"
              v-model="challengeAnswer" maxlength="40" class="form-control form-fields marbottom30" :class="classes">
            <span id="currentPassword-help-block" class="help-block para2 my-6">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider> -->

      <div class="vbg-ff-form-container-main"> 
        <label for="challengeQuestion" class="vbg-ff-input-label">Secret question</label>
        <div ref="dropdownRef"
          id="drpdn" 
          name="secret_question_dropdown"
          class="row dropdown dropdown-toggle" 
          v-bind:class="{ open: showDropdown }" 
          aria-haspopup="true" aria-expanded="false" 
          @click="toggleDropdown()" 
          @keyup.enter="toggleDropdown()" 
          tabindex="1"
        >
          <div class="text-flex1"> 
            <a id="dropdownMenu1" aria-haspopup="true" role="button"> 
              <span>{{challengeQuestionText}}</span>
            </a>
          </div>
          <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
          <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1"> 
            <li 
              v-for="(question, index) in secretQuestions" 
              :key="question.id" role="presentation" :tabindex="index+1" 
              v-bind:class="{ chosen: (question.question === challengeQuestionText), 'last-item': (index === secretQuestions.length - 1)}"
              @keyup.enter="questionSelected(question, 'enter')"
              @keyup="listkeyupHandler($event, index)"
              @click="questionSelected(question, 'click')"
              @keydown.tab="handleTabKey"
              ref="userSelectQuestion"
            >
              <a role="menuitem" @click="selectQuestion(question)">
                <span>
                  <span class="sqaText">{{question.question}}</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="vbg-ff-input-container">
          <label class="vbg-ff-input-label padleft0 ">Answer</label>
          <div class="vbg-icon-input-group">
            <input
              class="vbg-rsp-input-field"
              :class="(!isValidText && challengeAnswer) ? 'vbg-rsp-input-warning' : ''"        
              v-model="challengeAnswer"
              id="challengeAnswer"
              name="challengeAnswer"
              maxlength="40"
              @input="getbuttondisable"
              ref="submitButtonRef"
              :tabindex="secretQuestions.length+1"
              :type="isShow ? 'text' : 'password'"
            />
            <div class="vbg-show-icon">
              <span v-if="!isShow && challengeAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Show</span>
              <span v-if="isShow && challengeAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Hide</span>
            </div>
            <div class="vbg-rsp-warning-icon" style="right: 62px; top: 11px;" v-if="!isValidText && challengeAnswer">
              <img :src="warningIcon" />
            </div>
          </div>
          <div v-if="!isValidText && challengeAnswer">
            <p class="vbg-rsp-warning-msg" style=" margin-top: 8px; height: auto; " v-html="getWarningMessage" />
          </div>
        </div>
        <div class="subtext">{{ $t("message.sqa_page_subtext")}}</div>
      </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import downCaret_blk from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import commonMixin from "@/common/mixin/commonMixins";
    export default {
     name: 'SQAComponent',
     props: [
      "isProfileComplete",
     ],
     mixins: [commonMixin],
     data () {
       return {
        challengeQuestion: "",
        challengeQuestionText: "Select",
        challengeAnswer: "",
        showDropdown: false,
        selectboxError: "",
        isShow: false,
        invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
        warningIcon: warningIcon,
        downCaret: downCaret_blk,
        isValidText: false
       }
     },
     mounted() {
        var iamvar_appCookie;
        if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
          iamvar_appCookie = getCookie('X-VZ-C_APP')
        } 
        this.getSecretQuestions()
        document.addEventListener('click', (e) => {
          if(this.showDropdown && e.target.closest("#drpdn") === null) {
            if(iamvar_appCookie == 'MVB') {
              this.showDropdown = true;
            }
            else {
              this.showDropdown=false
            }
          }
        })
 
     },
     computed: {
      ...mapGetters("profile", [
      
        'secretQuestions',
         'firstName',
         'lastName',
         "sqa"
      ]),
      disableButton() {
        return (this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestionText != "Select"  ) ? false : true
      },
      getWarningMessage() {
        if(this.challengeAnswer.length < 3){
          return 'Your answer must be 3-40 characters.'
        } else if (this.invalidCharRegex.test(this.challengeAnswer)) {
          return 'Only letters, numbers, spaces, and periods are allowed.'
        } else if (!this.challengeAnswer) {
          return 'Please enter a valid secret answer.'
        }
      }
    
    },
     methods: {
        ...mapActions("profile", [
        'challengeQuestion',
        'challengeQuestionText',
        'challengeAnswer',
      'getSecretQuestions'
      ]),
      selectQuestion(selQuestion) {
        if (selQuestion != null) {
          this.challengeQuestion = selQuestion.id;
          this.challengeQuestionText = selQuestion.question;
        }
      
    },
    togle() {
      this.showDropdown = !this.showDropdown;
      if (!this.showDropdown && this.challengeQuestionText == "Select") {
        this.selectboxError = "Please select a Secret Question.";
      } else {
        this.selectboxError = "";
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    listkeyupHandler(e, index) {
      const questionList = this.$refs.userSelectQuestion;
      switch (e.key) {
        case "ArrowUp":
          if (index >= 1) {
            console.log(index);
            questionList[index - 1].focus();
          }
          if (index < 1) {
            questionList[questionList.length - 1].focus();
          }
          break;
        case "ArrowDown":
          if (index < questionList.length - 1) {
            questionList[index + 1].focus();
          } else {
            questionList[0].focus();
          }
          break;
        default:
          break;
      }
    },
    questionSelected(selectedQuestion, selectionType) {
      if (selectionType === "enter") {
        this.challengeQuestion = selectedQuestion.id;
        this.challengeQuestionText = selectedQuestion.question;
        this.$nextTick(() => this.$refs.submitButtonRef.focus());
      } else {
        this.$refs.dropdownRef.focus();
      }
    },
    handleTabKey(event) {
      const dropdownList = this.$refs.userSelectQuestion;
      
      //Checking whether the pressed key is tab and the focused element is the last element to close the dropdown
      if(event.key === 'Tab' && document.activeElement.classList.contains('last-item')) {
        this.toggleDropdown()
      }
    },
    getbuttondisable() {
      this.isValidText = false
      if(this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestionText != "Select" ) {
        this.isValidText = true
      }
    }
  },

     watch: {
      //requires in post auth flow
    sqa: function(newVal) {
      if (newVal && this.isProfileComplete) {
        this.challengeQuestionText = newVal.message;
        this.challengeQuestion= newval.id
      }
    },
       challengeQuestion: function() {
          this.$emit("child-challengeQuestion", this.challengeQuestion);
        },
        challengeAnswer: function() {
          this.$emit("child-challengeAnswer", this.challengeAnswer);
        },
        isShow: function(newVal) {
          if(newVal) {
            setTimeout(()=> {
                this.isShow = false
            },10000)
          }
        }
   }
    
    };
   </script>
   
<style scoped>
/* .dropdown-arrow {
  background: url("~@/common/assets/styles/corp/images/down_arrow.png")
    no-repeat;
  background-size: 14px;
  background-position-x: 95%;
  background-position-y: 8px;
  width: 40px;
  height: 22px;
  padding: 0px;
  text-decoration: none;
  border-bottom: none;
  position: absolute;
  right: 18px;
  bottom: 9px;
} */
.dropdown {
  padding: 5.5px 0px 5.5px 0px;
}
#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}  
.sqaText {
  white-space: normal;
}
.vbg-show-icon {
  height: 20px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 16px !important;
}
.dropdown {
  width: 596px !important;
  min-height: 44px !important;
  padding: 7px 14.6px 7px 12px !important;
  height: 44px !important;
  font-size: 16px;
}
.vbg-ff-input-label {
  line-height: 16px;
  font-family: 'VzNHGeTX-reg11' !important;
}
.dropdown-arrow {
  position: absolute; 
  right: 0px;
  bottom: 11px;
  width: 32.33px;
}
.vbg-rsp-input-field {
  width: 596px !important;
}
.subtext {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  color: #000;
}
.dropdown-menu {
  box-shadow: none !important;
}
.dropdown-menu li {
  border-bottom: 1px solid #CDCCCB;
}
.dropdown-menu li.chosen {
  border-left: 4px solid #D52B1E;
}
.text-flex1 { 
  flex: 1;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.vbg-ff-input-container{
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  display: flex;
  flex-direction: column;
}
.vbg-icon-input-group{
  width: 596px !important;
  position: relative;
}
.dropdown-menu > li > a {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}
.vbg-rsp-dropdown-cheveron {
  margin-right: 0px !important;
}
@media (max-width: 1024px) { 
  .vbg-icon-input-group{
    width: 100% !important;
  }
  .vbg-rsp-input-field {
    width: 100% !important;
  }
  .dropdown {
    width: 100% !important;
    /* need to disucss */
    min-height: 44px !important;
    height: auto !important;
  }
  .dropdown-menu li.chosen > a {
    color: #000 !important;
    padding-left: 12px !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    margin-left: 0px;
    padding-left: 12px !important;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.row.dropdown.dropdown-toggle {
  -ms-flex-align: center;
  align-items: center;
}
   </style>