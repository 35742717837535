<template>
  <div>
    <div class="modal-body" >
      <div class="modal-heading">
        <h1>Terms of Use</h1>
      </div>
      <div class="scrollable-content">
      <div class="modal-text-body">
        <h2 class="modal-sub-heading">1. Terms</h2>
        <p>
          By accessing the Verizon Enterprise Center (“VEC”), My Business (“MyBiz”), or other business portals (together the “Site”), you are agreeing to be bound by these Terms of Use (“Terms”), as amended based on your use of the Site on behalf of your company, agency or organization (“Company”).
        </p>
        <p>
          We may revise these online Terms from time to time. By using this Site after we have updated the Terms, you are agreeing to be bound by the then current version of these Terms. 
        </p>
        <h2 class="modal-sub-heading">2. Description of Site</h2>
        <p>
          The Site allows you to view and manage your business accounts for certain services provided to you by Verizon. These actions may include:
          <ul>
            <li> viewing and modifying the type or quantity of services you want to receive from Verizon;</li>
            <li>using tools to perform service-related functions such as routing traffic, monitoring usage, and generating reports;</li>
            <li>paying for Verizon services; </li>
            <li>purchasing certain third-party products and services sold through the Site (“Third Party Solutions”);  </li>
            <li> accessing service-related support resources made available to you through the Site; and</li>
            <li>accessing and using any other features or functions that we may make available on the Site from time to time.</li>
          </ul>
        </p>
        <h2 class="modal-sub-heading"> 3. Use License </h2>

        <p>
          Subject to your compliance with the Terms, Verizon grants you a limited, non-exclusive, non-sublicensable, non-transferable, and revocable right to access and use the Site only for your Company’s internal use and for no other pu
        </p>
        <h2 class="modal-sub-heading"> 4. Limited License to Content in the Site.</h2>
        <p>
          We grant your Company permission to make and use copies of the text, graphics, images, video and audio files and other information from the Site ("Content") solely for your internal, non-commercial use. Except for the foregoing limited license to make and internally use copies of the Content, nothing contained herein shall be construed as granting to you or your Company a license under any copyright, trademark, patent or other intellectual property right of Verizon or any third party. 
        </p>
        <p>
          Content may contain proprietary notices of Verizon or third parties. You agree not to remove or alter such notices if you make copies of the Content.
        </p>
        <h2 class="modal-sub-heading">5. Site Access</h2>

        <p>
          Access to the Site requires a unique username and password, which may not be shared with other users. Verizon provides your Company with an initial username and password after which Verizon is no longer responsible for any control, management, dissemination of, and creation of, new usernames and passwords. Any access to the Site via such username and password is 
          deemed by Verizon to be done with your Company’s authorization and you agree to be responsible for all activities that occur under your usernames and passwords including, but not limited to, any orders placed for products, services or solutions, any access that is provided to billing information, and any unauthorized access. You agree that you will exit from your account at the end of each session. In order to provide technical support and other services, Verizon representatives may access your account on the Site in order to perform one or more functions on your behalf. Your use of this Site is subject to Verizon’s <a href="https://www.verizon.com/about/privacy/" target="_blank">Privacy Policy.</a>
        </p>
        <h2 class="modal-sub-heading">6. Restrictions</h2>
        <p>
          You may not:
          <ul>
            <li> use any data mining robots ("bots"), hardware or software modules that add a specific feature or service by plugging into an existing larger system ("plug-ins"), or other data gathering and extraction tools, scripts, applications, or methods on this Site;</li>
            <li>   use any robotic process automation or software services in place of a human user to automate customer user actions on site;</li>
            <li>   use any device, software, or hardware to bypass any operational element or to interfere, or attempt to interfere, with the proper working of this Site or activities conducted therein; </li>
            <li> take any action that imposes an unreasonable or disproportionately large load on this Site or its network infrastructure or that adversely affects the Verizon network or other customers; </li>
            <li> decompile, reverse engineer, modify or disassemble any of the software in or associated with the Verizon network or any Verizon server, including software associated with the Site;</li>
            <li> use any meta tags or any other "hidden text" utilizing Verizon’s name or any Verizon trademark without Verizon’s prior written permission; or</li>
            <li> frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Verizon or use any Verizon trademark except as set forth in the foregoing limited license to Content in these Terms.</li>
          </ul>
        </p>
        <p>
          Verizon reserves the right to modify, reject or eliminate any information residing on or transmitted to its server that it, in its sole discretion, believes is unacceptable or in violation of these Terms and to suspend or end your access to the Site for any operational or governmental reason or violation of these Terms.
        </p>
        <p>You agree not to upload, post or submit any information or material to the Site that violates the intellectual property, confidentiality or privacy rights of any third party.</p>
        <h2 class="modal-sub-heading">7. Proprietary Rights </h2>
        <p>
          Verizon and its licensors retain and own all right, title and interest in and to the Site, including, without limitation, all copyrights, trademarks, service marks, patents, trade secrets and other intellectual property rights in and to the Site, and any modifications, corrections or enhancements thereto.
        </p>
        <p>
          We appreciate your suggestions about the Site. In providing feedback to us, you agree that (1) your feedback is entirely voluntary – you are not required to provide us with any evaluation, assessment, ideas, recommendations or other suggestions; (2) we may use, disclose, reproduce, distribute, or monetize your feedback as we choose; and (3) you are not entitled to fees, royalties, or compensation related to our use of your feedback. If we use your feedback to modify or improve the Site, you agree that Verizon will own all the intellectual property rights in those modifications and improvements.
        </p>
        <h2 class="modal-sub-heading">8. Disclaimers </h2>
        <p>
          The Site provided on an 'as is' basis. Verizon makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
        </p>
        <p>Verizon is not responsible for the unavailability of the Site due to causes beyond its control and planned periods of maintenance</p>
        <h2 class="modal-sub-heading">9. Limitation of Liability </h2>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VERIZON WILL NOT BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR INFORMATION, AND THE LIKE) OR ANY OTHER DAMAGES ARISING IN ANY WAY FROM OR IN CONNECTION WITH THE AVAILABILITY, USE, RELIANCE ON, PERFORMANCE OF THE SITE, PROVISION OF OR FAILURE TO PROVIDE THE SITE, LOSS OF DATA, YOUR ACCESS OR INABILITY TO ACCESS OR USE THE SITE OR YOUR USE AND RELIANCE ON INFORMATION OR CONTENT AVAILABLE ON OR THROUGH THE SITE, EVEN IF VERIZON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE.
        </p>
        <h2 class="modal-sub-heading">10. Links </h2>
        <p>
          The Site may include links to non-Verizon websites. Verizon is not responsible for the contents of any such linked website. The inclusion of any link does not imply endorsement by Verizon. Use of any such linked website is at your own risk.
        </p>
        <h2 class="modal-sub-heading">11. Governing Law and Venue </h2>
        <p>
          These Terms will be governed by and construed in accordance with the laws of New York, without regard to its choice of law principles, except where the Communications Act of 1934 applies. You waive all objections to venue in the US District Court for the Southern District of New York or state courts within the City of New York, as applicable. 
        </p>
        <h2 class="modal-sub-heading">12. Account Information </h2>
        <p>
          Account information provided to you as part of the Site is not the official record of your account or its activity. Your account statement, furnished to you by us in a paper format, or electronically if you are enrolled in paperless statements service, will remain the official record. Site information is generally updated regularly, but is subject to adjustment and correction and therefore should not be relied upon by you for taking or not taking any action, including payment.
        </p>
        <h2 class="modal-sub-heading">13. Third Party Solutions </h2>
        <p>
          The following additional terms will apply to Third Party Solutions.
          <ol>
            <li>a.  General. If you choose to obtain a Third Party Solution, it is solely your responsibility to ensure that you meet any system, device or other requirements for operation of that Third Party Solution.If you obtain a Third Party Solution on the Site, you understand and agree that Verizon is permitted to share certain account information to the provider of that Third Party Solution in order to facilitate billing, account creation and other administrative functions.</li>
            <li>b.  Accounts. In order to use a Third Party Solution, you may need to create an account with the third party provider or licensor of that Third Party Solution and you may need to link that account to your Verizon account for billing and other purposes. Any information that you provide to a third party may be subject to that third party’s (or another party’s) privacy policy. It is solely your responsibility to understand how your information may be accessed, used and shared by third parties in connection with Third Party Solutions.</li>
            <li>c.  Third Party EULA. Third Party Solutions may be subject to end user license agreements or similar terms and conditions between you and the third party licensors of those Third Party Solutions (each a “Third Party EULA”). Each Third Party EULA is a binding contract between you and a third party, and Verizon is not a party to that Third Party EULA. Third Party EULAs govern your rights to access and use the Third Party Solutions to which they apply and can be enforced against you. It is solely your responsibility to (i) read and understand, (ii) accept and (iii) comply with Third Party EULAs. Subject to the foregoing, Verizon will, where possible, endeavor to provide you with a link to applicable Third Party EULAs before you can complete a purchase or activation of the related Third Party Solutions. You acknowledge and agree that if you choose not to accept a Third Party EULA then you may not be able to access or use the related Third Party Solution.</li>
          </ol>
        </p>
        <h2 class="modal-sub-heading">14. Analytics </h2>
        <p>
          Some pages within the Site may incorporate Google Analytics. It is solely your responsibility to read and understand the Google Analytics terms.  For information about how Google Analytics collects and processes data, please visit <a href="http://www.google.com/policies/privacy/partners/" target="_blank">http://www.google.com/policies/privacy/partners/.</a> 
        </p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popupComponent",
   components: {
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.modal-body {
  margin: 64px 41px 116px 40px !important;
  padding: 0 !important;
  /* height: calc(100% - 98px) !important; */
  /* height: -webkit-fill-available; */
  height: calc(100% - 179px) !important;
  position: absolute;
}
.modal-body p {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 32px;
  text-align: justify;
}
.modal-body a {
  overflow-wrap: break-word;
  line-break: anywhere;
  text-decoration: underline;
}
.modal-body p ul {
  margin-left: 20px;
}
.modal-body p ul li {
  margin-left: 8px;
  list-style-type: disc;
}
.modal-heading {
  margin-bottom: 32px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px !important;
  font-weight: bold !important;
}
.scrollable-content {
  padding-right: 58px;
  height: calc(100% - 66px) !important;
  overflow-y: scroll; 
  position: relative;
}
.modal-sub-heading {
  margin-bottom: 16px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px !important;
  font-weight: bold !important;
  text-align: justify;
}


@media (max-width: 1024px) { 
  .modal-body{
    padding: 0 !important;
    text-align: justify;
    /* height: calc(100% - 220px) !important; */
    height: -webkit-fill-available !important;
  }
  .modal-heading {
    /* margin-left: 16px;
    margin-top: 72px; */
    margin-bottom: 32px;
    font-size: 24px !important;
    line-height: 24px !important;
  }
  .modal-sub-heading {
    line-height: 20px !important;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .modal-body{ margin: 72px 8px 0px 16px !important; }
  .scrollable-content {
    padding-right: 8px;
    height: calc(100% - 220px) !important;
  }
}
::-webkit-scrollbar {
  /* display: none; */
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #747676;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #D8DADA;
  border-radius: 10px;
  height: 375px;
}
</style>