<template>
  <div id="vz-gh20"></div>
</template>
<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {
    getLogo() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/vzlogoLarge.21ecaab0.png"
      );
    },
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    gnavdl = {
      bu: "smb",
      appid: "mybusinesslimited",
    };
  },
  mounted() {
    var globalNav = document.createElement("script");
    globalNav.setAttribute(
      "src",
      "https://www.verizon.com/business/ngnav/smb.js"
    );
    globalNav.setAttribute("async", "async");
    document.head.appendChild(globalNav);
  },
};
</script>
<style scoped>
.header {
  border-bottom: 1px solid #e4e5e3 !important;
}
.hlogo {
  display: block;
  float: left;
  width: 160px;
}
</style>