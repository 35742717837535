<template>
  <v-row class="clearfix padding-top-large padding-bottom-xlarge padding-left-small header">
    NotFoundComponent 
  </v-row>
</template>
<script>
export default {
  name: 'NotFoundComponent',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>
