<template>
  <BlockUI v-if="submitLoader" :url="url"></BlockUI>
  <div v-else-if="!submitLoader">
    <div class="vbg-ff-title-container">
      <h1 class="vbg-rsp-title-h1">
        {{ $t("message.forgot_pd_general_title") }}
      </h1>
    </div>
    <div style="height: 40px">
      <p>{{ $t("message.pwd_reset_previous_pds") }}</p>
    </div>
    <!-- <ValidationObserver v-slot="{ handleSubmit,invalid }"> -->
    <div class="vbg-ff-form-container">
      <form @submit.prevent="onSubmit()">
        <div>
          <div>
            <!-- <ValidationProvider
                name="currentPassword"
                rules="Passwordrequired"
                v-slot="{ errors ,classes }"
              >
                <div class="row form-group no-gutters">
                  <div class="col-12">
                    <label class="labeltiny" for="currentPassword">Current password*</label>
                    <input
                      v-model="currentPassword"
                      class="form-control form-fields"
                      type="password"
                      :class="classes"
                      id="currentPassword"
                      name="currentPassword"
                      aria-required="true"
                      placeholder="********"
                      maxlength="20"
                    >
                    <span
                      id="currentPassword-help-block"
                      class="help-block para2 my-6"
                    >{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider> -->
          </div>
        </div>
        <PasswordComponent
          @child-passwordBlkList="passwordBlkListValue"
          @child-newpassword="newpasswordValue"
          @child-confirmpassword="confirmpasswordValue"
          @child-disablePrimaryButton="disabledPrimaryButtonValue"
        ></PasswordComponent>
        <!-- <div>
          <div>
            <button
              :disabled="!passwordBlkList"
              :class="{ 'inactive': !passwordBlkList}"
              class=""
              type="submit"
              id="btn_update"
            >
              <span id="sp_sendcode">Create password</span>
            </button>

            <button
              class=""
              type="reset"
              @click="cancel()"
              id="btn_back"
            >
              <span id="sp_sendcode">Cancel</span>
            </button>
          </div>
        </div> -->
        <div class="vbg-ff-form-container-button-group">
          <button
            class="btn vbg-ff-btn-continue vbg-ff-btn-width200 spacing05"
            :class="disabledPrimaryButton == 'disabled' ? 'disabled' : false"
            style=""
            type="submit"
            :disabled="disabledPrimaryButton == 'disabled' ? true : false"
            ref="submitButton"
            id="modify_password_button"
            name="modify_password_button"
          >
            Change password
          </button>
          <button
            class="btn vbg-ff-btn-cancel vbg-ff-btn-width200 spacing05"
            @click="cancel()"
            style=""
            type="button"
            id="modify_cancel_button"
            name="modify_cancel_button"
          >
            Cancel
          </button>
        </div>
        <div style="margin-bottom: 80px"></div>
      </form>
    </div>
    <!-- </ValidationObserver> -->
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import PasswordComponent from "@/profile/components/core/PasswordComponent";
import MessagesComponent from "@/profile/components/core/MessagesComponent";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
export default {
  name: "ModifyPasswordComponent",
  data() {
    return {
      //url:iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      newpassword: "",
      confirmpassword: "",
      currentPassword: "",
      passwordBlkList: false,
      disabledPrimaryButton: false,
    };
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Profile:Change Password");
  },
  components: {
    PasswordComponent,
    MessagesComponent,
  },
  methods: {
    newpasswordValue: function (params) {
      this.newpassword = params;
    },
    confirmpasswordValue: function (params) {
      this.confirmpassword = params;
    },
    passwordBlkListValue: function (params) {
      this.passwordBlkList = params;
    },
    disabledPrimaryButtonValue: function (params) {
      console.log("disabledPrimaryButtonValue params", params);
      this.disabledPrimaryButton = params;
    },
    ...mapActions("profile", [
      "modifyPassword",
      "resetMessages",
      "getUserProfile",
      "isTxnValid",
      "getPassswordBlackList",
      "updateTargetUrl",
    ]),

    onSubmit() {
      let payload = {
        uuID: this.uuID,
        password: this.newpassword,
        confirmPassword: this.confirmpassword,
        // currentPassword: this.currentPassword,
      };
      if (!this.passwordBlkList) {
        eventTracking("Password Reset Initiated", "");
        this.modifyPassword(payload);
      }
    },
    moveover() {
      $("#PassInfo").popover({ trigger: "hover focus" });
    },
    cancel() {
      this.resetMessages();
      this.$router.push({
        path: "overview",
        query: { target: this.logOutGotoUrl },
      });
    },
  },

  computed: {
    ...mapGetters("profile", [
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "uuID",
      "submitLoader",
      "gotoProfileSettings",
      "attributedUpdated",
      "stepupVerified",
      "logOutGotoUrl",
    ]),
    isDisabledClass() {
      if (
        !this.userPwd ||
        !this.userReenteredPwd ||
        this.userPwd != this.userReenteredPwd ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return "disabled";
      } else {
        return "";
      }
    },
    isDisabledButton() {
      if (
        !this.userPwd ||
        !this.userReenteredPwd ||
        this.userPwd != this.userReenteredPwd ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // URLSearchParams not supported in IE
    // let params = new URLSearchParams(window.location.search)
    if (this.getParameterByName("stepupVerification")) {
      this.$store.commit("profile/setStepupVerified", true);
    } else {
      this.$store.commit("profile/setStepupVerified", false);
    }
    this.$store.commit("profile/setAttributedUpdated", "modifypassword");
     this.getUserProfile(this.getParameterByName("target"));
    if (iamvar_oneSessionPerLoginEnable !== true) {
      this.isTxnValid(this.getParameterByName("target"));
    }
    this.updateTargetUrl(this.getParameterByName("target"));
    this.resetMessages();
     this.getPassswordBlackList();
    //  this.readFormInputs("pwd_reset_component")
    this.changeContextBiocatch('postauth_modify_password_page')
    sessionStorage.removeItem('optSelected')
  },
  watch: {
    gotoProfileSettings: function (newVal) {
      if (newVal) {
        this.$router.push({
          path: "overview",
          query: { target: this.logOutGotoUrl },
        });
      }
    },
  },
};
</script>
<style scoped>
/* .passErrormsg1 {
  color: rgb(205, 4, 11);
  font-weight: bold;
}

.passErrormsg2 {
  color: btn_primary_black;
  font-weight: bold;
}

.matched {
  text-decoration: line-through;
}
li {
  list-style-type: disc;
 
}

@media (min-width: 350px) and (max-width: 550px) {
  .setup-secure-profile {
    
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .oauth-button {
    width: 47% !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }
  .tablet-secondary-buttom {
    float: right;
  }
  .createPwsdButton {
    margin-right: 15px;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1024px) {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .oauth-row {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .oauth-mw-100 {
    max-width: 100% !important;
  }
  .oauth-plr0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .oauth-p0 {
    padding: 0 !important;
  }
  .oauth-flex1 {
    flex: 1;
  }
  .setup-secure-profile {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .vbg-ff-form-container{
    margin-top: 24px !important;
  }
}

@media (min-width: 1025px) {
  .col-8.oauth-flex1.oauth-mw-100.oauth-p0 {
    width: 50% !important;
    max-width: 50% !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 768px) {
  .oauth-button {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .createPwsdButton {
    margin-bottom: 16px;
  }
} */
/* ******************************* */
/* modified css  for desktop view*/
.vbg-ff-form-container {
  margin-top: 48px !important;
}
.disabled {
  background-color: #d8dada !important;
}
.vbg-ff-form-container-button-group {
  margin-top: 88px;
}
.vbg-ff-btn-width200 {
  width: 200px !important;
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}

/* ******************************************** */
@media (min-width: 769px) and (max-width: 1024px) {
  .vbg-ff-form-container {
    margin-top: 32px !important;
  }
  .vbg-ff-form-container-button-group {
    margin-top: 72px !important;
  }
  .vbg-ff-btn-width200 {
    width: 47% !important;
  }
}
@media (max-width: 768px) {
  .vbg-ff-form-container {
    margin-top: 32px !important;
  }
  .vbg-ff-btn-width200 {
    width: 100% !important;
  }
  .vbg-ff-btn-cancel {
    /* margin-bottom: 12px; */
    margin-top: 12px;
  }
  /* .vbg-ff-form-container-button-group {
    display: flex;
    flex-direction: column-reverse;
  } */
  .vbg-ff-form-container-button-group {
    margin-top: 72px;
  }
}
</style>
